import React from "react";
import "./style.css";
import { Workshops } from "..";
import { Checkout } from "../../../components/Checkout";
import { getClientInfo } from "../../../hooks/clientInfo";
import { dayNames, formatter, monthNames } from "../../../dateutils/names";
import { saveImage } from "../../../hooks/images";

let image = "/api/v1/images?id=9cfe65a90ea8476a94801521fae5dd23.jpg";
let posts = [
  {
    caption:
      "Nos estamos preparando para recibir a todos nuestrosparticipantes!.",
    image: image,
    user: {
      name: "Borigui",
      image: image,
    },
  },
];

class PurchasedWorkshopDetails extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {
      posts: null,
      postImage: null,
      postComment: null,
      uploading: false,
      addPost: false,
    };
  }

  async tempSaveimageFile() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));
      component.setState((p) => ({
        postImage: event.target.files[0],
        uploading: false,
      }));
    };
    input.click();
  }

  async uploadPost() {
    let imageURL = await saveImage(this.state.postImage, null);

    let postData = {
      image: imageURL,
      caption: this.state.postComment,
      entity_type: "workshops",
      entity_id: this.data._id,
    };

    let req = await fetch("/api/v1/posts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData),
    });

    if (req.status == 200) {
      let data = await req.json();
      this.setState(() => ({
        posts: null,
        postImage: null,
        postComment: null,
      }));
    }
  }

  async deletePost(postId) {
    let req = await fetch(`/api/v1/posts/${postId}`, {
      method: "DELETE",
    });

    this.setState(() => ({ posts: null }));
  }

  async loadPosts() {
    let req = await fetch(
      `/api/v1/posts?entity_type=workshops&entity_id=${this.data._id}`
    );

    if (req.status == 200) {
      let data = await req.json();
      this.setState(() => ({ posts: data.posts }));
    }
  }

  render() {
    let detail = this.data;

    if (!this.state.posts) {
      this.loadPosts();
    }

    let addPost;
    if (this.state.addPost) {
      addPost = (
        <div className="add-post">
          <div className="image-input" onClick={() => this.tempSaveimageFile()}>
            <img
              src={
                this.state.postImage &&
                URL.createObjectURL(this.state.postImage)
              }
            ></img>
            <p>Subir imagen</p>
          </div>
          <textarea
            placeholder="Escribe aqui tu comentario"
            onChange={(e) =>
              this.setState(() => ({ postComment: e.target.value }))
            }
          ></textarea>
          <button onClick={() => this.uploadPost()}>enviar</button>
        </div>
      );
    } else {
      addPost = (
        <div
          className="toggle-add"
          onClick={() => this.setState(() => ({ addPost: true }))}
        >
          <img className="icon"></img>
          <p>Subir Post</p>
        </div>
      );
    }

    let time = new Date(detail.date.year, detail.date.month, detail.date.day);
    let date = `${dayNames[time.getDay()]}, ${time.getDate()} ${
      monthNames[time.getMonth()]
    } ${time.getFullYear()}`;

    return (
      <div className="workshop-detail granted">
        <div className="workshop-header">
          <img src={detail.image}></img>
          <h1>{date}</h1>
        </div>

        <div>
          <h1>{detail.name}</h1>
          <p>
            Duración <span>{detail.time / 60} horas</span>{" "}
          </p>
          <p>
            Facilitado por <span>{detail.owner_name}</span>{" "}
          </p>
          <p>
            Lugar <span>{detail.location}</span>{" "}
          </p>
        </div>
        <div>
          <h1>Descripcion</h1>
          <p>{detail.description}</p>
        </div>
        <br></br>
        <div>
          {this.state.posts &&
            this.state.posts.map((post) => {
              return (
                <div className="post">
                  <div className="user">
                    <img src={post.user && post.user.image}></img>
                    <p>{post.user && post.user.name}</p>
                  </div>
                  <img src={post.image}></img>
                  <div className="interactions">
                    <div className="field">
                      <img className="icon icon-fav-product"></img>
                      <p>12</p>
                    </div>
                  </div>
                  <p>{post.caption}</p>
                  <p onClick={() => this.deletePost(post._id)}>Delete post</p>
                </div>
              );
            })}
          {addPost}
        </div>
        <div className="nav-ops">
          <p onClick={() => this.root.setComponent(Workshops)}>Volver</p>
        </div>
      </div>
    );
  }
}

class WorkshopDetail extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    // console.log(this.data);
    this.state = {};
  }

  async purchase() {
    // let result = await getClientInfo();
    // // console.log(result);
    // if (!result || Object.keys(result).length === 0) {
    //   let purchaseInvoiceResult = await generatePurchase("workshops", this.data._id);
    // }
    // await generatePurchase("workshops", this.data._id);
    this.root.setComponent(Checkout, null, {
      entityType: "workshops",
      entityId: this.data._id,
    });
  }

  render() {
    let detail = this.data;
    if (this.data.purchase) {
      return (
        <PurchasedWorkshopDetails
          root={this}
          data={this.data}
        ></PurchasedWorkshopDetails>
      );
    }

    let time = new Date(detail.date.year, detail.date.month, detail.date.day);
    let date = `${dayNames[time.getDay()]}, ${time.getDate()} ${
      monthNames[time.getMonth()]
    } ${time.getFullYear()}`;

    return (
      <div className="workshop-detail">
        <div className="workshop-header">
          <img src={detail.image}></img>
          <h1>{date}</h1>
        </div>

        <div>
          <h1>{detail.name}</h1>
          <p>
            Duración <span>{detail.time / 60} horas</span>{" "}
          </p>
          <p>
            Facilitado por <span>{detail.owner_name}</span>{" "}
          </p>
          <p>
            Lugar <span>{detail.location}</span>{" "}
          </p>
        </div>
        <div>
          <h1>Descripcion</h1>
          <p>{detail.description}</p>
          <p className="price">
            Valor del cupo:{" "}
            <span>{formatter.format(detail.plans[0].price)}</span>
          </p>
        </div>
        
        <div className="nav-ops">
          <p className="accent-option" onClick={() => this.purchase()}>Reservar Cupo</p>
          <p onClick={() => this.root.setComponent(Workshops)}>Volver</p>
        </div>
      </div>
    );
  }
}

export { WorkshopDetail };
