import React from "react";
import { QuizEditor, SistemicTestEditor } from "../Blocks/Editors/quiz";
import { BlockEditor } from "../Blocks";
import { VideoEditor } from "../Blocks/Editors/video";
import { sleep } from "../../../../../dateutils/names";

let pageTypeMap = {
  block: BlockEditor,
  video: VideoEditor,
  quiz: QuizEditor,
  "sistemic-test": SistemicTestEditor,
};

let pageTemplateMap = {
  block: {
    type: "block",
    content: [],
  },
  video: {
    type: "video",
    src: null,
    snapshot: "some-snapshot-url.png",
  },
  quiz: {
    type: "quiz",
    title: "This is a something quiz",
    description:
      "A brief description about this quiz and the thing it's measuring",
    content: [],
  },
  "sistemic-test": {
    type: "sistemic-test",
    title: "This is a card selection page",
    description: "Please select one of the options on each page",
    content: [],
  },
};

class PageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {};
  }

  addPage(template) {
    this.root.setState((p) => {
      let pages = p.pages;
      if (!pages) {
        pages = [];
      }

      pages.push(pageTemplateMap[template]);
      return { pages: pages, alert: null };
    });
  }

  close() {
    this.root.setState(() => ({ alert: null }));
  }

  render() {
    return (
      <div className="module-alert">
        <h1>Añadir Pagina</h1>
        <div className="page-type-options">
          <div className="page-type-item" onClick={() => this.addPage("block")}>
            <p>Bloque de Texto</p>
            <img className="icon"></img>
          </div>

          <div className="page-type-item" onClick={() => this.addPage("video")}>
            <p>Leccion en Video</p>
            <img className="icon"></img>
          </div>

          <div className="page-type-item" onClick={() => this.addPage("quiz")}>
            <p>Prueba Multiopcion</p>
            <img className="icon"></img>
          </div>

          <div
            className="page-type-item"
            onClick={() => this.addPage("sistemic-test")}
          >
            <p>Prueba Sistemica</p>
            <img className="icon"></img>
          </div>
        </div>
        <button onClick={() => this.close()}>Cancelar</button>
      </div>
    );
  }
}

class PageManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.index = props.index;
    this.pages = props.pages;
    this.settings = props.data;
  }
  render() {
    let Component = pageTypeMap[this.settings.type];
    return (
      <Component
        root={this.root}
        data={this.settings}
        index={this.index}
        pages={this.pages}
      ></Component>
    );
  }
}

export { PageManager, PageSelector };
