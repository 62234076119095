import React from 'react';
import './style.css'

class Help extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='help-desk'>
                <h1>Gracias por confiar en nosotros</h1>
                <p>Te presentamos las opciones disponibles para comunicarte con nosotros</p>
                <div className='help-options'>
                    <p>WhatsApp</p>
                    <p>Correo Electronico</p>
                </div>
            </div>
        )
    }
}

export {
    Help
}