import React from "react";
import './style.css';
import TherapyDetail from "../TherapyDetail";
import { COP, formatter } from "../../dateutils/names";


class PackPurchaseConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.root = props.root;
        this.data = props.data;
        this.state = {}
    }

    async purchasePack() {
        const resp = await fetch(
            '/api/v1/packs',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    service_id: this.data.service._id,
                    plan: this.data.planIndex,
                    sessions: Number(this.data.plan.sessions)
                })
            }
        )
        if (resp.status == 200) {
            window.open('/?section=packs', '_self');
        }
    }
    render() {
        return (
            <div className="purchase-pack-confirm animated-intro">
                <h1>Estas a punto de adquirir este paquete por</h1>
                <p className="normal-price">{formatter.format(this.data.plan.price)}</p>
                <p>Deseas continuar?</p>
                <button onClick={()=>this.purchasePack()}>Continuar</button>
                <div className='nav-ops'>
                    <p onClick={(e)=>{
                        this.root.setComponent(PacksConfig, null, this.data)
                    }}>Regresar</p>
                </div>
            </div>
        )
    }
}


class PacksConfig extends React.Component {
    constructor(props) {
        super(props);
        this.root = props.root;
        this.data = props.data;
        this.state = {
            alert: null,
            alertConfig: {
                msg: "Default Alert message",
                callback: null
            }
        }
    }

    async purchasePack() {
        const resp = await fetch(
            '/api/v1/packs',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    service_id: this.data.service._id,
                    plan: this.data.planIndex,
                    sessions: Number(this.data.plan.sessions)
                })
            }
        )

    }

    render() {
        // // console.log(this.data);
        let sessions = this.data.plan.sessions;
        let oneSessionPlan;

        this.data.service.plans.forEach(plan => {
            if (Number(plan.sessions) == 1 || !plan.sessions) {
                oneSessionPlan = plan;
            }
        });

        let planDiffPrice = Number(oneSessionPlan.price) - (Number(this.data.plan.price) / Number(this.data.plan.sessions));
        let regularPrice = Number(oneSessionPlan.price) * Number(this.data.plan.sessions);
        let discountPercent = Math.round(100 - (Number(this.data.plan.price) * 100 / regularPrice));

        let sessionsBadges = [];

        for (let i=0; i < Number(this.data.plan.sessions); i++) {
            sessionsBadges.push((<img src={this.data.service.image}></img>));
        }

        let packContainer = (
            <div className="packs-container ">
                <p className="sessions-info">{sessions}</p>
                <p>sesiones</p>
                <div className="sessions-info-container">
                    {
                        sessionsBadges
                    }
                </div>
                <h1>Paquete de {this.data.service.name}</h1>
                
                <br></br>
                <p className="regular-price">{formatter.format(regularPrice, true)}</p>
                <p className="normal-price">{formatter.format(this.data.plan.price, true)}</p>
                <p className="discount-percent">{discountPercent} % OFF</p>
                <button onClick={()=>this.root.setComponent(PackPurchaseConfirmation, null, this.data)}>Adquirir</button>
            </div>
        )


        let packsView = this.data.service.plans.map((plan, index) => {
            if (Number(plan.sessions) && Number(plan.sessions) > 1 && (plan.sessions != this.data.plan.sessions)) {
                return (
                    <div className="packs-container collapsed" onClick={()=>this.root.setComponent(PacksConfig, null, {service: this.data.service, plan: plan, planIndex: index})}>
                        <h1>{plan.sessions || 1} {plan.sessions ? 'sesiones': 'sesion'} por </h1>
                        <p> $ {plan.price} COP</p>
                    </div>
                )
            } else if (Number(plan.sessions) == Number(this.data.plan.sessions)) {
                return (
                    packContainer
                )
            }
        });

        return (
            <div className="packs-view animated-intro">
                {packsView}
                <div className='nav-ops'>
                    <p onClick={(e)=>{
                        this.root.setComponent(TherapyDetail, null, this.data.service)
                    }}>Regresar</p>
                </div>
            </div>
        )
    }
}

export {
    PacksConfig
}