async function getTherapists() {
    let req = await fetch(
        '/api/v1/therapists'
    )
    if (req.status == 200) {
        let data = await req.json();
        cachedTerapists = data.therapists;
        return data.therapists;
    }
}

let cachedTerapists;
let currentTherapist;

async function getCurrentTherapistInfo() {
    if (!cachedTerapists) {
        cachedTerapists = await getTherapists();
    }
    if (!currentTherapist) {
        return cachedTerapists[0];
    } else {
        return currentTherapist;
    }
    // let therapistId = localStorage.getItem('therapist');
    // if (!therapistId) {
    //     return cachedTerapists[0];
    // }
    // let filtered = cachedTerapists.filter((ther)=>ther._id == therapistId);

    // if (filtered && filtered.length > 0) {
    //     return filtered[0];
    // }
}

async function getGlobaltherapists() {
    if (!cachedTerapists) {
        cachedTerapists = await getTherapists();
    }
    return cachedTerapists;
}

async function setCurrentTherapist(therapist) {
    if (therapist) {
        currentTherapist = therapist;
        localStorage.setItem('therapist', therapist._id);
    }
    
}

export {
    getTherapists,
    cachedTerapists,
    getCurrentTherapistInfo,
    getGlobaltherapists,
    setCurrentTherapist
}