import React from "react";
import "./style.css";
import { formatter, newUUID } from "../../../dateutils/names";

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data.order;
    this.root = props.root;
    this.orders = props.data.orders;
    this.state = {
      expandEvents: false,
      expandDocuments: false,
      data: this.data,
    };
  }

  close() {
    this.root.renderComponent(null, null);
  }

  async downloadDocument(doc) {
    let base64String = "data:application/pdf;base64," + doc.content;
    let downloadLink = document.createElement("a");
    downloadLink.href = base64String;
    downloadLink.download = `${doc.typeCode}.pdf`;
    downloadLink.click();
  }

  render() {
    // console.log(this.state.data);
    // console.log(this.state);
    let totalSales = 0;
    this.state.data.products.forEach((product) => {
      totalSales += product.prices[0].price * product.units;
    });

    let shipments;
    if (this.state.data.purchase.shipments) {
      if (Array.isArray(this.state.data.purchase.shipments)) {
        shipments = this.state.data.purchase.shipments;
      } else if (typeof this.state.data.purchase.shipments === "object") {
        shipments = [];
        Object.entries(this.state.data.purchase.shipments).map(
          ([admin, shipment]) => {
            shipments.push(shipment);
          }
        );
      }
    }

    return (
      <div className="service-creator order-detail-manager">
        <img className="icon icon-back" onClick={() => this.close()}></img>
        <h1>Detalles del pedido</h1>
        <label>Usuario</label>
        <p>{this.state.data.user.name}</p>
        <label>Direccion de entrega</label>
        <p>Calle # -</p>
        <br></br>
        {this.state.data.products.map((product) => {
          return (
            <div className="order-detail-item">
              <img src={product.image}></img>
              <div>
                <p>{product.name}</p>
                <p>Unidades: {product.units}</p>
              </div>
              <p>
                {formatter.format(
                  Math.ceil(product.units * product.prices[0].price)
                )}
              </p>
            </div>
          );
        })}
        <br></br>
        <p className="order-price">
          Total de venta: {formatter.format(totalSales)}
        </p>
        <br></br>
        {shipments &&
          Object.entries(shipments).map(([admin, shipment]) => {
            return (
              <div className="shipping-card">
                <h1>Seguimiento del envio de productos</h1>
                <br></br>

                <p>
                  Estado:{" "}
                  {shipment.events &&
                    (shipment.events.status == "Success"
                      ? "Entregado"
                      : "En espera")}
                </p>

                <div className="estimated-shipping">
                  <h1>Fecha y hora de recogida:</h1>
                  <p>
                    {
                      shipment.shipmentDetails[0].pickupDetails
                        .localCutoffDateAndTime
                    }
                  </p>
                </div>

                <div className="estimated-shipping">
                  <h1>Entrega estimada:</h1>
                  <p>{shipment.estimatedDeliveryDate.estimatedDeliveryDate}</p>
                </div>

                {this.state.expandDocuments ? (
                  <div className="shipment-documents expanded-shipment-option">
                    <img
                      className="icon icon-collapse-card"
                      onClick={() =>
                        this.setState(() => ({ expandDocuments: false }))
                      }
                    ></img>
                    <h1>Documentos</h1>
                    <p>
                      Descarga los documentos que necesita tu paquete para la
                      recogida en tu ubicacion
                    </p>
                    <div className="shipment-labels">
                      {shipment.documents.map((doc) => {
                        return (
                          <div>
                            <img
                              onClick={() => this.downloadDocument(doc)}
                            ></img>
                            <p>{doc.typeCode}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div
                    className="expandable"
                    onClick={() =>
                      this.setState(() => ({ expandDocuments: true }))
                    }
                  >
                    <p>Documentos</p>
                  </div>
                )}

                {shipment.events && this.state.expandEvents ? (
                  <div className="shipment-events expanded-shipment-option">
                    <img
                      className="icon icon-collapse-card"
                      onClick={() =>
                        this.setState(() => ({ expandEvents: false }))
                      }
                    ></img>
                    <h1>Eventos</h1>
                    <div className="shipment-events-container">
                      {shipment.events.events.map((event) => {
                        return (
                          <div className="shipment-event">
                            <h1>{event.date}</h1>
                            <p>{event.description}</p>
                            <p>{event.serviceArea.code}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div
                    className="expandable"
                    onClick={() =>
                      this.setState(() => ({ expandEvents: true }))
                    }
                  >
                    <p>Eventos</p>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.userInfo;
    this.state = {
      expanded: false,
      orders: null,
      message: "",
    };
  }

  toggle() {
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  async getOrders() {
    let req = await fetch(`/api/v1/admin/orders`);
    if (req.status == 200) {
      let data = await req.json();
      let orders = data.orders;
      this.setState(() => ({
        orders: orders,
      }));
    } else if (req.status == 500) {
      await this.getOrders();
    }
  }

  async orderDetail(order) {
    this.root.renderComponent(OrderDetail, {
      order: order,
      orders: this.state.orders,
      userInfo: this.userInfo,
    });
  }

  render() {
    let content;
    let message = this.state.message;

    if (!this.state.orders && this.state.expanded) {
      this.getOrders();
      return (
        <div
          className={`orders-manager ${this.state.expanded ? "expanded" : ""}`}
          key={newUUID()}
        >
          <div className="service-main-actions" onClick={() => this.toggle()}>
            <p>Cargando Pedidos</p>
          </div>
        </div>
      );
    }

    if (this.state.expanded) {
      content = (
        <div className="accounting-content" key={newUUID()}>
          <p>{message}</p>
          {this.state.orders &&
            this.state.orders.map((order) => {
              let totalSales = 0;
              order.products.forEach((product) => {
                totalSales += product.prices[0].price * product.units;
              });
              if (totalSales > 0) {
                return (
                  <div
                    key={newUUID()}
                    className="order-card"
                    onClick={() => this.orderDetail({ ...order })}
                  >
                    <img src={order.products[0].image}></img>
                    <div>
                      <h1>Productos ({order.units})</h1>
                      <p>{formatter.format(totalSales)}</p>
                      <p>Estado: {order.status}</p>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      );
    }
    return (
      <div
        className={`orders-manager ${this.state.expanded ? "expanded" : ""}`}
        key={newUUID()}
        onClick={() => {
          if (!this.state.expanded) {
            this.toggle();
          }
        }}
      >
        <div className="service-main-actions">
          {this.state.expanded ? (
            <>
              <h1>Manejo de Pedidos</h1>
              <div onClick={() => this.toggle()}>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Manejo de Pedidos</h1>
            </>
          )}
        </div>
        {this.state.expanded && content}
      </div>
    );
  }
}

export { Orders };
