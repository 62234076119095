import React from "react";
import { sleep } from "../../../../../dateutils/names";

class DeleteAlert extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
  }

  close() {
    this.root.setState(() => ({ alert: null }));
  }

  render() {
    return (
      <div className="module-alert fixed">
        <h1>{this.data.title}</h1>
        <p>{this.data.description}</p>
        <div className="alert-buttons">
          <button
            style={{ color: "var(--neutral)" }}
            onClick={() => this.close()}
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              this.data.callback();
              this.close();
            }}
          >
            Borrar
          </button>
        </div>
      </div>
    );
  }
}

export { DeleteAlert };
