
async function getClientInfo() {
    const resp = await fetch('/api/v1/users/info');
    if (resp.status == 200) {
        let json = await resp.json();
        globalClientInfo = json.response;
        return json.response;
    } else {
        return {};
    }

}

async function getCountries() {
    const resp = await fetch('/api/v1/countries');
    if (resp.status == 200) {
        let json = await resp.json();
        return json.countries;
    } 
    return {}
}


async function getCities(country) {
    const resp = await fetch('/api/v1/cities?country=' + country);
    if (resp.status == 200) {
        let json = await resp.json();
        return json.cities;
    }
    return {}
}


let globalClientInfo;

async function getGlobalClientInfo(reload=false) {
    if (!globalClientInfo || reload) {
        globalClientInfo = await getClientInfo();
    }

    return globalClientInfo; 
}

export {
    getClientInfo,
    globalClientInfo,
    getGlobalClientInfo,
    getCities,
    getCountries
}