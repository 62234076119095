import React from "react";
import "./styles.css";
import {
  mockSchedules,
  countryCodes,
  APPO_STATUS_CODES,
  timezonesContries,
} from "./constants";
import { monthNames, dayNames, LoadingComp } from "../Appointments";
import { sleep } from "../../App";
import UserAppointment, { checkerOn } from "./UserAppointment";
import { getAppointments } from "../../hooks/appointments";
import { PacksAndDiscounts } from "./PacksAndDiscounts";
import { getCities } from "../../hooks/clientInfo";
import { SearchList } from "../../components/Search/index.js";
import { formatter, newUUID, range } from "../../dateutils/names.js";
import { EnrolledCourses } from "./Courses/index.js";

class ProductInvoiceCard extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = { ...this.data, expanded: false };
  }

  toggle() {
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  render() {
    let kart = this.state;

    if (!this.state.expanded) {
      return (
        <div
          className="user-kart-detail collapsed animated-intro"
          onClick={() => this.toggle()}
        >
          <h1>Productos: {kart.list.length}</h1>
          <p>{formatter.format(kart.total)}</p>
        </div>
      );
    } else {
      let shipments;
      if (Array.isArray(kart.purchase.shipments)) {
        shipments = kart.purchase.shipments;
      } else if (typeof kart.purchase.shipments === "object") {
        shipments = [];
        Object.entries(kart.purchase.shipments).map(([admin, shipment]) => {
          shipments.push(shipment);
        });
      }
      return (
        <>
          <div
            className="user-kart-detail animated-intro"
            onClick={() => this.toggle()}
          >
            <div
              style={{
                display: "block",
              }}
            >
              <p>Fecha: {kart.purchase.last_updated}</p>
              <p>Ordenes de envio</p>
              {shipments &&
                shipments.map((shipment) => {
                  return (
                    <div className="shipping-order-badge">
                      <label>Vendedor</label>
                      <p>{shipment.seller && shipment.seller.name}</p>
                      <label>
                        <span>tracking id:</span>{" "}
                        {shipment.shipmentTrackingNumber}
                      </label>
                      <label>Fecha estimada de entrega</label>

                      <p>
                        {shipment.estimatedDeliveryDate.estimatedDeliveryDate}
                      </p>
                      <button>Seguir Envio</button>
                    </div>
                  );
                })}
            </div>

            {kart.products.map((product) => {
              return (
                <div>
                  <img src={product.image}></img>
                  <h1>{product.name}</h1>
                  <p>{formatter.format(product.prices[0].price)}</p>
                </div>
              );
            })}
            <div
              style={{
                borderBottom: "none",
              }}
            >
              <img className="icon" style={{ opacity: 0 }}></img>
              <h1>Productos: {kart.list.length}</h1>
              <p>{formatter.format(kart.total)}</p>
            </div>
          </div>
        </>
      );
    }
  }
}

class ProductsInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {};
  }
  render() {
    console.log(this.data);
    return (
      <div className="user-kart-container" key={newUUID()}>
        <div>
          {this.data.karts.map((kart) => {
            return (
              <ProductInvoiceCard root={this} data={kart}></ProductInvoiceCard>
            );
          })}
        </div>
        <div className="nav-ops">
          <p
            onClick={() =>
              this.root.setComponent(Profile, "Espacio Personal", {})
            }
          >
            Volver
          </p>
        </div>
      </div>
    );
  }
}

class Dates extends React.Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.root = props.root;
    this.state = {
      appointments: null,
      appointment: null,
    };
  }
  async getAppointments() {
    let appointments = await getAppointments();

    // Sort Appointments by date
    let compFn = (a, b) => {
      if (
        new Date(`${a.date.year}-${a.date.month}-${a.date.day}`) >
        new Date(`${b.date.year}-${b.date.month}-${b.date.day}`)
      ) {
        return 1;
      }
      if (
        new Date(`${a.date.year}-${a.date.month}-${a.date.day}`) <
        new Date(`${b.date.year}-${b.date.month}-${b.date.day}`)
      ) {
        return -1;
      }

      return 0;
    };

    this.setState(() => ({ appointments: appointments.sort(compFn) }));
  }

  openAppointment(appointment) {
    // this.parent.openAppointment(appointment);
    this.root.setComponent(UserAppointment, "Detalle de Cita", appointment);
  }

  render() {
    if (!this.state.appointments) {
      this.getAppointments();
      return <></>;
    }
    const data = this.state.appointments;
    let dates = data.map((appointment) => {
      let hour =
        appointment.time > 12
          ? `${appointment.time - 12}pm`
          : `${appointment.time}am`;
      let time = `${hour}`;
      let payStatus;

      let appo_status = APPO_STATUS_CODES.getByCode(appointment.status);

      payStatus = (
        <p className={`pay-status ${appo_status.style}`}>{appo_status.label}</p>
      );

      return (
        <div
          key={appointment._id}
          className="date-badge"
          onClick={() => this.openAppointment(appointment)}
        >
          <div className="date-badge-day">
            <p>{dayNames[appointment.date.weekDay]}</p>
            <h1>{appointment.date.day}</h1>
            <p>{monthNames[appointment.date.month - 1].slice(0, 3)}</p>
          </div>
          <div className="date-badge-info">
            <h1>{appointment.service.name}</h1>
            <p>{time}</p>
            {payStatus}
          </div>
        </div>
      );
    });
    return (
      <div className="content appointments-list">
        <h1>Citas programadas</h1>
        <p>Encuentra información sobre horarios y como llegar</p>
        <div className="user-dates-container">{dates}</div>

        <div className="nav-ops">
          <p
            onClick={(e) => {
              this.root.setComponent(Profile, "Espacio Personal");
            }}
          >
            Regresar
          </p>
        </div>
      </div>
    );
  }
}

class WorkShops extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {};
  }

  render() {
    const data = []; //  mockSchedules;
    let dates = data.map((date) => {
      let hour =
        date.date.time > 12
          ? `${date.date.time - 12}pm`
          : `${date.date.time}am`;
      let time = `${hour}`;
      return (
        <div key={date.id} className="date-badge">
          <div className="date-badge-day">
            <p>{dayNames[date.date.weekDay]}</p>
            <h1>{date.date.day}</h1>
            <p>{monthNames[date.date.month - 1].slice(0, 3)}</p>
          </div>
          <div className="date-badge-info">
            <h1>{date.service.name}</h1>
            <p>{time}</p>
            <h2>{APPO_STATUS_CODES.getByCode(date.status).label}</h2>
          </div>
        </div>
      );
    });
    return (
      <div className="content booked-workshops">
        <h1>Talleres Reservados</h1>
        <p>Encuentra información sobre horarios y como llegar</p>
        {dates}
        <div className="nav-ops">
          <p
            onClick={() => this.root.setComponent(Profile, "Espacio Personal")}
          >
            Regresar
          </p>
        </div>
      </div>
    );
  }
}

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.nameInputRef = React.createRef();
    this.root = props.root;
    this.state = {
      inputName: {
        edit: false,
        value: "",
      },
      inputBirth: {
        date: new Date(),
        edit: false,
      },
      inputContact: {
        edit: false,
        data: null,
      },
      user: null,
      cities: null,
    };
  }

  async clientinfo() {
    const resp = await fetch("/api/v1/users/info");
    if (resp.status == 200) {
      const data = await resp.json();
      // // console.log(data);
      const user = data.response;
      let cities;
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let country =
        user && user.contact && user.contact.country
          ? user.contact.country
          : timezonesContries.filter((tm) => tm.timezones.includes(timezone))[0]
              .name;
      if (!this.state.cities) {
        cities = await getCities(country);
      }
      this.setState((p) => ({
        user: user,
        inputName: {
          edit: false,
          value: user.name,
        },
        inputBirth: {
          edit: false,
          date: user.birth
            ? new Date(user.birth.year, user.birth.month - 1, user.birth.day)
            : new Date(),
        },
        inputContact: {
          edit: false,
          data: user.contact,
        },
        cities: cities || p.cities,
      }));
    } else {
      window.open("login", "_self");
    }
  }

  async focusInput() {
    await sleep(600);
    this.nameInputRef.current.focus();
  }

  allowBirth(show) {
    this.setState((p) => ({
      inputBirth: { edit: show, date: p.inputBirth.date },
      user: show ? p.user : null,
    }));
  }

  allowContact(show) {
    this.setState((p) => {
      let data = {};
      if (p.user) {
        data["dial_code"] =
          (p.user.contact && p.user.contact.dial_code) || "+57";
        data["phone"] = p.user.contact && p.user.contact.phone;
        data["email"] = p.user.email || "example@gmail.com";
        data["country"] =
          (p.user.contact && p.user.contact.country) || "Colombia";
        data["city"] = (p.user.contact && p.user.contact.city) || "Cali";
      }
      return {
        inputContact: { edit: show, data: data || {} },
        user: show ? p.user : null,
      };
    });
  }

  updateBirth(year, month, day) {
    this.setState((p) => {
      let mo = month ? month : p.inputBirth.date.getMonth();
      let ye = year ? year : p.inputBirth.date.getFullYear();
      let da = day ? day : p.inputBirth.date.getDate();
      return {
        inputBirth: {
          edit: true,
          date: new Date(ye, mo, da),
        },
      };
    });
  }

  saveBirth() {
    // // console.log("Saving birth");
    // // console.log(this.state.inputBirth);
    this.updateUserBirth();
  }

  saveContactInfo() {
    this.updateUserContactInfo();
  }

  async updateContactInfo(key, val) {
    let cities;
    if (key == "dial_code") {
      let country = countryCodes.filter((c) => val == c.dial_code)[0];
      // // console.log(country);
      // // console.log(val);
      cities = await getCities(country.name);
      // // console.log(cities);
    }

    let data;

    this.setState((p) => {
      let contact = { ...p.inputContact };
      contact.data[key] = val;
      data = data;
      // // console.log(contact, cities, p.cities);
      return {
        inputContact: contact,
        cities: cities ? cities : p.cities,
      };
    });
  }

  async updateUserContactInfo() {
    const contact = this.state.inputContact.data;
    for (let co of countryCodes) {
      if (co.dial_code == contact.dial_code) {
        contact["country"] = co.name;
      }
    }

    // // console.log(contact);
    const resp = await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ contact: contact }),
    });
    this.clientinfo();
  }

  async updateName() {
    const resp = await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: this.state.inputName.value }),
    });
    if (resp.status == 202) {
      this.clientinfo();
      // this.setState((p)=>({inputName: {edit: false, value: p.inputName.value}}))
    }
  }

  async updateUserBirth() {
    const birthDate = this.state.inputBirth.date;
    const resp = await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        birth: {
          month: birthDate.getMonth() + 1,
          year: birthDate.getFullYear(),
          day: birthDate.getDate(),
        },
      }),
    });
    this.clientinfo();
  }

  openAppointment(appointment) {
    this.setState(() => ({ appointment: appointment }));
  }

  async logout() {
    await fetch("/api/v1/auth/logout");
    window.open("/", "_self");
  }

  openView(Component, title, data = {}) {
    this.root.setComponent(Component, title, data);
  }

  render() {
    if (!this.state.user) {
      this.clientinfo();
      return LoadingComp;
    }
    let profileName = this.state.inputName.edit ? (
      <input
        ref={this.nameInputRef}
        className="name-input"
        onKeyDown={(e) => e.key == "Enter" && this.updateName()}
        onMouseLeave={() => this.updateName()}
        onChange={(e) =>
          this.setState(() => ({
            inputName: { edit: true, value: e.target.value },
          }))
        }
        placeholder="Nombre"
        value={this.state.inputName.value}
      />
    ) : (
      <h1
        onClick={() => {
          this.focusInput();
          this.setState((p) => ({
            inputName: { edit: true, value: p.inputName.value },
          }));
        }}
      >
        {(this.state.user && this.state.user.name) || "Editar Nombre"}
      </h1>
    );
    let birthInfo;
    if (this.state.inputBirth.edit) {
      birthInfo = (
        <div className="input-birth">
          <div className="birth-option">
            <p>Dia</p>
            <select
              defaultValue={this.state.inputBirth.date.getDate()}
              onChange={(e) => this.updateBirth(null, null, e.target.value)}
            >
              {range(1, 31).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
          <div className="birth-option">
            <p>Mes</p>
            <select
              defaultValue={this.state.inputBirth.date.getMonth()}
              onChange={(e) => this.updateBirth(null, e.target.value, null)}
            >
              {monthNames.map((month, index) => {
                return (
                  <option key={month} value={index}>
                    {month}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="birth-option">
            <p>Año</p>
            <select
              defaultValue={this.state.inputBirth.date.getFullYear()}
              onChange={(e) => this.updateBirth(e.target.value, null, null)}
            >
              {range(1930, 2006)
                .reverse()
                .map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
            </select>
          </div>
          <button onClick={() => this.saveBirth()}>Guardar</button>
        </div>
      );
    } else {
      let time = this.state.inputBirth.date;
      let date = `${dayNames[time.getDay()]}, ${time.getDate()} ${
        monthNames[time.getMonth()]
      } ${time.getFullYear()}`;
      birthInfo = (
        <div onClick={() => this.allowBirth(true)}>
          <p>{date}</p>
        </div>
      );
    }
    let contactInfo;

    if (this.state.inputContact.edit) {
      // // console.log("rendering input components again");
      // // console.log(this.state.cities);
      // // console.log(this.state.user);
      contactInfo = (
        <div className="contact-input">
          <div>
            <SearchList
              key={newUUID()}
              root={this}
              keys={["dial_code", "name"]}
              value="dial_code"
              callback="updateContactInfo"
              list={countryCodes}
              label="Pais"
              default={this.state.inputContact.data.dial_code}
            ></SearchList>
            {/*{<select
              className="phone-select"
              key={getRandomUUID()}
              onChange={(e) =>
                this.updateContactInfo("dial_code", e.target.value)
              }
              defaultValue={this.state.inputContact.data.dial_code}
            >

               {countryCodes.map((code) => {
                return (
                  <option key={code.name} value={code.dial_code}>
                    {code.dial_code} {code.name}
                  </option>
                );
              })}
            </select>*/}
            <div className="search-list">
              <p>Numero de telefono</p>
              <input
                type="number"
                className="phone-input"
                placeholder="Phone Number"
                onChange={(e) =>
                  this.updateContactInfo("phone", e.target.value)
                }
                value={
                  this.state.inputContact.data.phone || "Ingresa tu telefono"
                }
              ></input>
            </div>
          </div>
          <div className="contact-option">
            <SearchList
              key={newUUID()}
              list={this.state.cities}
              label="Ciudad"
              root={this}
              value="city"
              callback="updateContactInfo"
              default={this.state.inputContact.data.city}
            ></SearchList>
            {/* <select
              key={getRandomUUID()}
              className=""
              onChange={(e) => this.updateContactInfo("city", e.target.value)}
              defaultValue={this.state.inputContact.data.city}
            >
              {
                this.state.cities ? this.state.cities.map((city) => {
                return (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  );
                }) : null}
            </select> */}
            <div className="search-list">
              <p>Correo</p>
              <input
                type="email"
                placeholder="Your email"
                onChange={(e) =>
                  this.updateContactInfo("email", e.target.value)
                }
                value={this.state.inputContact.data.email}
              ></input>
            </div>
          </div>
          <button onClick={() => this.saveContactInfo()}>Guardar</button>
        </div>
      );
    } else {
      contactInfo = (
        <div className="contact-info" onClick={() => this.allowContact(true)}>
          <h1>
            {(this.state.user &&
              this.state.user.phone &&
              `${this.state.user.phone}`) ||
              "Agregar Celular"}
          </h1>
          <p>{(this.state.user && this.state.user.email) || "Agregar email"}</p>
          <p>
            {(this.state.user &&
              this.state.user.contact &&
              `${this.state.user.contact.city}, ${this.state.user.contact.country}`) ||
              "Agregar Ciudad, Pais"}
          </p>
        </div>
      );
    }

    return (
      <div className="profile-container animated-intro">
        {profileName}
        {birthInfo}
        {contactInfo}

        <div className="options">
          <div
            className="profile-option"
            onClick={() => this.openView(Dates, "Citas Programadas")}
          >
            <p>Citas programadas</p>
            <img className="icon icon-schedule"></img>
          </div>

          <div
            className="profile-option"
            onClick={() =>
              this.openView(EnrolledCourses, "Cursos en progreso", {
                discounts: this.state.user.discounts,
                packs: this.state.user.packs,
              })
            }
          >
            <p>Cursos en progreso</p>
            <img className="icon icon-courses"></img>
          </div>

          {/* <div
          className="profile-option"
          onClick={() =>
            this.openView(ProductsInvoices, "Productos Comprados", {
              karts: this.state.user.karts,
            })
          }
        >
          <p>Productos Comprados</p>
          <img className="icon icon-box"></img>
        </div> */}

          <div
            className="profile-option"
            onClick={() =>
              this.openView(PacksAndDiscounts, "Paquetes y Cupones", {
                discounts: this.state.user.discounts,
                packs: this.state.user.packs,
              })
            }
          >
            <p>Paquetes y Cupones</p>
            <img className="icon icon-box"></img>
          </div>
          <div
            className="profile-option"
            onClick={() => this.openView(WorkShops, "Talleres Reservados")}
          >
            <p>Talleres reservados</p>
            <img className="icon icon-yoga"></img>
          </div>
          <div className="profile-option">
            <p>Citas asistidas</p>
            <img className="icon icon-check-circle"></img>
          </div>
          {/* <Dates parent={this}></Dates>
                <WorkShops></WorkShops> */}
        </div>

        <div className="nav-ops">
          {this.state.user && this.state.user.access == "admin" ? (
            <p
              className="admin-btn"
              onClick={() => window.open("/admin", "_self")}
            >
              Admin Panel
            </p>
          ) : undefined}
          <p onClick={() => this.logout()}>Cerrar sesion</p>
        </div>
      </div>
    );
  }
}

export { Dates };
