import { newUUID } from "./dateutils/names";
const mockServices = [
  {
    id: '1234',
    name: "Sesión de psicoterapia",
    description: "Espacio de escucha activa y comprensión de 60 minutos (1 hora) orientado en compartir herramientas psicoterapéuticas para promover el autoconocimiento y bienestar inspirado en técnicas integrativas.",
    time: 60,
    plans: [
      {
        price: 100000,
        sessions: 1,
        usd_price: 20
      },
      {
        price: 360000,
        sessions: 4,
        usd_price: 20
      }
    ]
  },
  {
    id: '1235',
    name: "Sesiones psicoterapéuticas",
    description: "grupo de sesiones inspiradas en promover un espacio terapéutico que facilite la comprensión de los procesos terapéuticos con diversas herramientas humanistas e integrativas.",
    time: 60,
    plans: [
      {
        price: 100000,
        sessions: 1,
        usd_price: 20
      }
    ]
  },
  {
    id: '1236',
    name: "Psicoterapia integrativa",
    description: "Psicoterapia con enfoque humano e integral: espacio de bienestar inspirado en herramientas psicoterapéuticas integrales al servicio de la vida, la conciencia y el potencial humano.",
    time: 60,
    plans: [
      {
        price: 100000,
        sessions: 1,
        usd_price: 20
      },
      {
        price: 360000,
        sessions: 4,
        usd_price: 73
      }
    ]
  }
]

function getRandomUUID() {
  return newUUID();
}

export { mockServices, getRandomUUID };