import React from "react";
import { saveImage } from "../../../hooks/images";

class ProfileEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageData: null,
      name: null,
      updatingName: false,
    };
  }

  async getUserImage() {
    const resp = await fetch("/api/v1/users/info");
    if (resp.status == 200) {
      const data = await resp.json();
      // // console.log(data);
      this.setState(() => ({
        updatingName: false,
        imageData: data.response.profile_image || "no_image",
        name: data.response.professional_name,
      }));
      // const user = data.response;
    }
  }

  async saveImage(contents) {
    await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ profile_image: contents }),
    });
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = async (ev) => {
      let contents = ev.target.result;
      this.saveImage(contents);
      component.setState(() => ({ imageData: contents }));
    };
    reader.readAsDataURL(file);
  }

  uploadPhoto() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".png,.jpg,jpeg";
    input.onchange = async (event) => {
      let url = await saveImage(event.target.files[0], this.state.imageData);
      this.saveImage(url);
      component.setState(() => ({ imageData: url }));
    };
    input.click();
  }

  async saveName() {
    await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ professional_name: this.state.name }),
    });
    this.getUserImage();
  }

  render() {
    if (!this.state.imageData) {
      this.getUserImage();
    }
    return (
      <div className="profile-image animated-intro">
        {!this.state.updatingName && (
          <p onClick={() => this.setState(() => ({ updatingName: true }))}>
            {this.state.name}
          </p>
        )}
        {this.state.updatingName && (
          <>
            <input
              type="text"
              value={this.state.name || ""}
              onChange={(e) => this.setState(() => ({ name: e.target.value }))}
            />
            <button onClick={() => this.saveName()}>Guardar</button>
          </>
        )}
        {this.state.imageData && this.state.imageData != "no_image" ? (
          <img
            className="icon"
            onClick={() => this.uploadPhoto()}
            src={this.state.imageData}
          ></img>
        ) : (
          <div className="add-photo-icon">
            <img
              className="icon icon-empty-profile"
              onClick={() => this.uploadPhoto()}
            ></img>
            <p>subir foto</p>
          </div>
        )}
      </div>
    );
  }
}

export { ProfileEditor };
