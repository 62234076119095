async function getProducts(therapist) {
    let query = '';
    if (therapist) {
        query = `?therapist=${therapist}`
    }
    const resp = await fetch(
        '/api/v1/products' + query
    )
    if (resp.status == 200) {
        const data = await resp.json();
        return [data.response, data.accounting_data]
    }
    return [{error: "failed to fetch products"}, null]
}

async function saveProduct(data) {
    const resp = await fetch(
        '/api/v1/admin/products',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    if (resp.status == 200) {
        const data = await resp.json();
        return data.product_id;
    }
}


async function deleteProduct(id) {
    const resp = await fetch(
        `/api/v1/admin/products/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    if (resp.status == 200) {
        return;
    }
}

export {
    getProducts,
    saveProduct,
    deleteProduct
}