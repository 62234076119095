import React from "react";
import { formatter } from "../../dateutils/names";
import "./style.css";
import { BaseComponent } from "./base";
import { AdminDetail } from "./Admins";

class SuperAdminMain extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      admins: null,
      metrics: {
        totalSales: 1000000,
        marketPlaceProfit: 15000,
        boldCosts: 39900,
        deliveryCosts: 24000,
      },
    };
  }

  async getAdmins() {
    let request = await fetch(`/api/v1/management/admin-data`);
    if (request.status == 200) {
      let data = await request.json();
      this.setState(()=>({
        admins: data.metrics
      }))
      console.log(request);
    }
  }

  async getAdminsTest() {
    this.setState(() => ({
      admins: [
        {
          _id: "123456",
          name: "Test admin name",
          professional_name: "",
          image: null,
          status: 1,
        },
        {
          _id: "123456",
          name: "Another test admin",
          professional_name: "",
          image: null,
          status: 1,
        },
      ],
    }));
  }

  render() {
    if (!this.state.admins) {
      this.getAdmins();
      return <></>;
    }
    return (
      <div className="super-admin-main animated-intro">
        <div>
          <div className="currency-chart">
            <h1>Total Sales</h1>
            <p>{formatter.format(this.state.metrics.totalSales)}</p>
          </div>
          <div className="currency-chart">
            <h1>Comissiones Bold</h1>
            <p>{formatter.format(this.state.metrics.boldCosts)}</p>
          </div>
          <div className="currency-chart">
            <h1>Marketplace Profit</h1>
            <p>{formatter.format(this.state.metrics.marketPlaceProfit)}</p>
          </div>
          <div className="currency-chart">
            <h1>Shipment Costs</h1>
            <p>{formatter.format(this.state.metrics.deliveryCosts)}</p>
          </div>
        </div>
        <div>
          <br></br>
          <h1>Registered Admins</h1>
          {this.state.admins.map((admin) => {
            return (
              <div
                onClick={() => this.root.renderComponent(AdminDetail, admin)}
                className="admin-card"
              >
                <img src={admin.profile_image}></img>
                <p>{admin.name}</p>
                <h1>{adminStatus[admin.status]}</h1>
              </div>
            );
          })}
        </div>
        <div className="nav-ops">
          <p onClick={() => window.open("/admin", "_self")}>Salir</p>
        </div>
      </div>
    );
  }
}

let adminStatus = ["registrado", "activo", "inactivo"];

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: SuperAdminMain,
      data: {},
    };
  }

  renderComponent(component, data) {
    if (!component) {
      component = SuperAdminMain;
    }
    this.setState(() => ({ component: component, data: data }));
  }

  render() {
   // this.loadData();
    let Component = this.state.component;
    let componentView = (
      <Component root={this} data={this.state.data}></Component>
    );

    return <div className="super-admin animated-intro">{componentView}</div>;
  }
}

export { SuperAdmin };
