async function getServices(therapist) {
    let query = '';
    if (therapist) {
        query = `?therapist=${therapist}`
    }
    const resp = await fetch(
        '/api/v1/services' + query
    )
    if (resp.status == 200) {
        const data = await resp.json();
        const accountingData = data.accounting_data;
        return [data.response, accountingData]
    } else if (resp.status == 404) {
        return [{'error': 'Not therapist or service found'}, null]
    }
    return [[], null]
}

async function saveService(data) {
    const resp = await fetch(
        '/api/v1/admin/services',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    if (resp.status == 200) {
        const data = await resp.json();
        return data.service_id;
    }
}


async function deleteService(id) {
    const resp = await fetch(
        `/api/v1/admin/services/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    if (resp.status == 200) {
        return;
    }
}

export {
    getServices,
    saveService,
    deleteService
}