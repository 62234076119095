import React from "react";
import "./styles.css";
import { sleep } from "../../App";
import { dayNames, monthNames } from "../../dateutils/names";
import TherapyDetail from "../TherapyDetail";
import { getGlobalClientInfo } from "../../hooks/clientInfo";
import { getCurrentTherapistInfo } from "../../hooks/therapists";
import { COP } from "../../dateutils/names";

function getAllDaysInMonth(year, month) {
  const date = new Date(year, month, 1);
  const dates = [];
  while (date.getMonth() === month) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

function getWeeksInMonth(year, month) {
  const date = new Date(year, month, 1);
  let weeks = [];
  let buffer = [];
  while (date.getMonth() == month) {
    buffer.push(new Date(date));
    if (date.getDay() == 6) {
      weeks.push(Object.assign([], buffer));
      buffer = [];
    }
    date.setDate(date.getDate() + 1);
  }

  return weeks;
}

const LoadingComp = (
  <div className="loading">
    <h1>Cargando...</h1>
  </div>
);

class ErrorPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.text = props.text;
  }

  async unmount() {
    await sleep(2000);
    this.parent.hideConfirm();
  }

  render() {
    this.unmount();
    const content = (
      <>
        <h1>{this.text}</h1>
      </>
    );
    return (
      <div className="confirm-container">
        <div className="confirm-card">{content}</div>
      </div>
    );
  }
}

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.title = props.title;
    this.date = props.date;
    this.time = props.time;
    this.location = props.location;
    this.service = props.service;
    this.parent = props.parent;
    this.state = {
      loading: false,
      completed: false,
      error: false,
    };
  }

  hide() {
    this.parent.hideConfirm();
  }

  confirmation() {
    this.scheduleDate();
    this.setState(() => ({ loading: true }));
  }

  async scheduleDate() {
    const { status, callback, text, error } =
      await this.parent.saveAppointment();
    await sleep(3000);
    this.callback = callback;
    this.title = text;
    this.setState(() => ({ loading: false, completed: status, error: error }));
  }

  async checkout() {
    await sleep(4000);
    this.callback();
  }

  render() {
    let date = `${dayNames[this.date.getDay()]}, ${this.date.getDate()} de ${
      monthNames[this.date.getMonth()]
    }`;
    let time = this.time > 12 ? `${this.time - 12} pm` : `${this.time} am`;
    let content;
    if (this.state.loading) {
      content = (
        <>
          <h1>Estamos agendando tu sesion</h1>
          <p>te redireccionaremos a tus Citas Agendadas</p>
          <br></br>
          <div className="icon load-gif"></div>
        </>
      );
    } else if (this.state.error) {
      content = (
        <>
          <h1>{this.title}</h1>
          <p>Porfavor intentalo nuevamente</p>
          <button>intentar de nuevo</button>
        </>
      );
    } else {
      content = (
        <>
          <h1>{this.title}</h1>
          <h1>{this.service.name}</h1>
          <p>{date}</p>
          <p>{time}</p>
          <h1>Ubicación: {this.location}</h1>
          {!this.state.completed && (
            <>
              <button onClick={() => this.hide()}>Editar</button>
              <button className="grant" onClick={() => this.confirmation()}>
                Continuar
              </button>
            </>
          )}
        </>
      );
    }

    if (this.state.completed) {
      this.checkout();
    }

    return (
      <div className="confirm-container">
        <div className="confirm-card">{content}</div>
      </div>
    );
  }
}

class PackOrCuponSelector extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.packs = props.data.packs;
    this.cupons = props.data.cupons;
    this.parent = props.parent;
    this.state = {
      id: props.data.id,
      entity: props.data.entity,
      data: props.data.data,
      updated: false,
    };
  }

  async update() {
    let clientInfo = await getGlobalClientInfo(true);
    if (clientInfo) {
      this.packs = clientInfo.packs.filter(
        (pack) => pack.service._id == this.data.service_id
      );
      this.cupons = clientInfo.discounts;
      this.setState((p) => ({ updated: true }));
    }
  }

  selectItem(entity, id, data) {
    this.setState((p) => {
      if (id == p.id) {
        return { id: null, entity: null, data: null };
      }
      return { id: id, entity: entity, data: data };
    });
  }

  updateParentEntity() {
    this.parent.updatePackOrDiscount(
      this.state.entity,
      this.state.id,
      this.state.data
    );
  }

  render() {
    if (!this.state.updated) {
      this.update();
      return LoadingComp;
    }
    let cuponsView;
    if (this.cupons) {
      cuponsView = this.cupons.map((cupon) => {
        let cuponStyle =
          this.state.entity == "cuponId" && this.state.id == cupon._id
            ? "selected"
            : "";
        return (
          <div
            className={`cupon-item ${cuponStyle}`}
            onClick={() => this.selectItem("cuponId", cupon._id, cupon)}
          >
            <p>$ {COP(cupon.amount)}</p>
            <img
              className="icon icon-gift"
              style={{
                backgroundColor: "var(--neutral)",
                marginBottom: "4px",
              }}
            ></img>
          </div>
        );
      });
    }

    let packsView;
    if (this.packs) {
      packsView = this.packs.map((pack) => {
        let discounts = 0;
        if (pack.discounts) {
          discounts = pack.discounts.length;
        }
        let remaining = Number(pack.sessions) - discounts;
        let packStyle =
          this.state.entity == "packId" && this.state.id == pack._id
            ? "selected"
            : "";
        return (
          <div
            className={`pack-item ${packStyle}`}
            onClick={() => this.selectItem("packId", pack._id, pack)}
          >
            <img src={pack.service.image}></img>
            <p>{pack.service.name}</p>
            <p>
              {remaining}/{pack.sessions}
            </p>
          </div>
        );
      });
    }
    return (
      <div className="packs-cupon-selector">
        <p>Elige con que Cupon o Paquete quieres adquirir esta cita</p>
        <h1>Paquetes</h1>
        <div className="selector-list">
          {packsView || <p>No hay paquetes disponibles</p>}
        </div>

        <h1>Cupones</h1>
        <div className="selector-list">
          {cuponsView || <p>No hay cupones disponibles</p>}
        </div>

        <div className="nav-ops">
          <p className="next-btn" onClick={() => this.updateParentEntity()}>
            Listo!
          </p>
          <p onClick={() => this.parent.setState(() => ({ modal: null }))}>
            cerrar
          </p>
        </div>
      </div>
    );
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.parent = props.parent;
    this.data = props.data;
    this.root = props.root;
    this.service = this.data.service;

    this.state = {
      clientInfo: null,
      date: null,
      week: null,
      times: null,
      loading: true,
      time: null,
      confirm: null,
      location: "online",
      therapistInfo: null,
      modal: null,
      notifyDay: 1,
      notifyHour: 8,
    };
  }

  updatePackOrDiscount(discountEntity, id, data) {
    if (!discountEntity) {
      this.data["cuponId"] = null;
      this.data["packId"] = null;
    } else {
      this.data[discountEntity] = id;
    }
    if (discountEntity == "packId") {
      this.data["cuponId"] = null;
    } else if (discountEntity == "cuponId") {
      this.data["packId"] = null;
    }

    this.data["entityData"] = data;
    this.setState(() => ({ modal: null }));
  }

  async getTherapists() {
    let therapist = await getCurrentTherapistInfo();
    if (therapist) {
      this.setState(() => ({ therapistInfo: therapist }));
    } else {
      this.setState(() => ({ therapistInfo: {} }));
    }
  }

  async saveAppointment() {
    const queryParams = new URLSearchParams(window.location.search);
    const plan = Number(queryParams.get("plan"));
    const appointment = {
      service_id: this.service._id,
      date: {
        day: this.state.date.getDate(),
        month: this.state.date.getMonth() + 1,
        year: this.state.date.getFullYear(),
        weekDay: this.state.date.getDay(),
      },
      plan: plan,
      location: this.state.location,
      time: this.state.time,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      _id: this.data._id,
      pack_id: this.data.packId,
      cupon_id: this.data.cuponId,
    };

    const resp = await fetch("/api/v1/appointments", {
      method: this.data._id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(appointment),
    });

    // // // console.log(resp.status);

    if (resp.status != 201) {
      if (resp.status == 401) {
        // // // console.log('')
        const data = await resp.json();
        return {
          status: true,
          text: "Ahora registrate o logeate para continuar con el pago.",
          callback: () => {
            window.open(`/login?tmp=${data.tmp_user_id}`, "_self");
          },
        };
      } else {
        // // // console.log('Failed to Save appointment')
        // // // console.log(appointment);
        return {
          status: false,
          text: "Ocurrio un error al crear tu cita, intentalo nuevamente",
          callback: () => {
            window.open("/profile", "_self");
          },
        };
      }
    } else {
      const data = await resp.json();
      return {
        status: true,
        text: "Hemos agendado tu cita!!",
        callback: () => {
          window.open(
            `/?section=appointment&id=${data.appointment_id}`,
            "_self"
          );
        },
      };
    }
  }

  async updateDate(date, week) {
    await sleep(600);
    this.setState(() => ({ date: date, week: week, loading: false }));
  }

  selectTime(time) {
    this.setState(() => ({ time: time }));
  }

  async updateWeek(offset) {
    let newDate;
    if (!this.state.date) {
      newDate = new Date();
      newDate.setDate(newDate.getDate() + 1);
    } else {
      newDate = new Date(this.state.date);
    }
    if (offset > 0) {
      let forward = 6 - newDate.getDay();
      newDate.setDate(newDate.getDate() + forward + 1);
    } else {
      let backward = newDate.getDay() + 1;
      newDate.setDate(newDate.getDate() - backward);
    }

    let availableDays = this.getAvailableWeekDays(newDate);

    if (offset > 0) {
      newDate = availableDays[0];
    } else {
      newDate = availableDays[availableDays.length - 1];
      if (newDate < new Date()) {
        newDate = new Date();
        newDate.setDate(newDate.getDate() + (7 - newDate.getDay()));
        availableDays = this.getAvailableWeekDays(newDate);
        newDate = availableDays[0];
      }
    }

    this.checkAvailability(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate()
    );
  }

  async checkAvailability(year, month, day) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(year, month, day);

    const resp = await fetch(
      `/api/v1/appointments/availability?day=${date.getDate()}&month=${
        date.getMonth() + 1
      }&year=${date.getFullYear()}&hours=${Math.round(
        this.service.time / 60
      )}&timezone=${timezone}&therapist=${this.service.owner}`
    );
    let times = [];
    if (resp.status == 200) {
      const data = await resp.json();
      times = data.response;
    }
    this.setState((p) => ({
      loading: false,
      times: times,
      date: date,
      time: null,
    }));
  }

  hideConfirm() {
    this.setState(() => ({ confirm: null }));
  }

  schedule() {
    if (!this.state.time) {
      this.setState(() => ({
        confirm: (
          <ErrorPopUp
            parent={this}
            text="Porfavor elige la hora de tu cita"
          ></ErrorPopUp>
        ),
      }));
    } else {
      let title = `Confirma los datos de tu cita`;
      this.setState(() => ({
        confirm: (
          <Confirm
            parent={this}
            title={title}
            date={this.state.date}
            time={this.state.time}
            location={this.state.location}
            service={this.service}
          ></Confirm>
        ),
      }));
    }
  }

  getAvailableWeekDays(date) {
    let week = [];

    let offset = date.getDay();

    let tmpDate = new Date(date);
    tmpDate.setDate(tmpDate.getDate() - offset);

    for (let i = 0; i <= 6; i++) {
      week.push(new Date(tmpDate));
      tmpDate.setDate(tmpDate.getDate() + 1);
    }
    let weeklyAv = this.state.therapistInfo.weekly_availability;
    let availableDays = [];
    week.forEach((day) => {
      if (weeklyAv[day.getDay()].enabled) {
        availableDays.push(day);
      }
    });
    return availableDays;
  }

  renderDayBadges(availableWeekDays) {
    const today = new Date();
    let days = availableWeekDays.map((day, index) => {
      let className = day > today ? `day-badge` : `day-badge disabled`;
      const colorPalette = [
        "var(--primary-shadow)",
        "rgba(--second-shadow)",
        "rgba(--second",
      ];
      const color = colorPalette[day.getMonth() % 3];
      let colorStyle = {
        color: color,
        boxShadow: `${color} 0px 0px 15px -4px inset `,
      };

      if (day.getMonth() != today.getMonth()) {
        className = `${className} diff-month`;
      }
      if (
        day.getDate() == this.state.date.getDate() &&
        day.getMonth() == this.state.date.getMonth() &&
        day.getFullYear() == this.state.date.getFullYear() &&
        day > today
      ) {
        className = `${className} current`;
      }
      return (
        <div
          key={index}
          className={className}
          style={colorStyle}
          onClick={() =>
            day > today &&
            this.checkAvailability(
              day.getFullYear(),
              day.getMonth(),
              day.getDate()
            )
          }
        >
          <h1>{day.getDate()}</h1>
          <p>{dayNames[day.getDay()].slice(0, 3)}</p>
        </div>
      );
    });
    return days;
  }

  async showCupons() {
    let data = {
      packs: this.state.clientInfo.packs || [],
      cupons: this.state.clientInfo.discounts || [],
      id: this.data.packId || this.data.cuponId,
      data: this.data.entityData || null,
      service_id: this.service._id,
      entity: this.data.packId ? "packId" : "cuponId",
    };
    this.setState(() => ({
      modal: (
        <PackOrCuponSelector data={data} parent={this}></PackOrCuponSelector>
      ),
    }));
  }

  async clientInfo() {
    let clientinfo = await getGlobalClientInfo();
    this.setState(() => ({ clientInfo: clientinfo }));
  }

  async notifyMe(hour, day) {
    // // console.log(hour);
    // // console.log(day);
    let req = await fetch("/api/v1/notifyme", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hour: hour,
        day: day,
      }),
    });
  }

  render() {
    if (!this.state.clientInfo) {
      this.clientInfo();
      return <></>;
    }
    let date;
    let today = new Date();
    date = new Date();
    let availableWeekDays = [];

    if (this.state.date) {
      date = this.state.date;
    }

    if (!this.state.date && this.state.therapistInfo) {
      date = new Date();
      date.setDate(date.getDate() + 1);
      availableWeekDays = this.getAvailableWeekDays(date);

      let lastAv = availableWeekDays.slice(-1)[0];
      // // console.log(date);
      // // console.log(lastAv);

      if (!lastAv) {
        let email = this.state.clientInfo && this.state.clientInfo.email ? <><h2>{this.state.clientInfo.email}</h2></> : (<>
          <input placeholder="e-mail"></input>
        </>);
        // // console.log(email);
        return (
          <div className="notify-me animated-intro">
            <h1>Detalle del servicio</h1>
            <p>{this.service.description}</p>
            <p>
              En el momento no hay horarios disponibles, porfavor indicanos hora
              y dia de tu preferencia y te avisaremos a tu correo
            </p>

            {email}
            <label>Hora</label>
            <input
              value={this.state.notifyHour}
              onChange={(e) =>
                this.setState(() => ({ notifyHour: e.target.value }))
              }
            ></input>
            <br></br>
            <label>Dia</label>
            <select
              value={this.state.notifyDay}
              onChange={(e) =>
                this.setState(() => ({ notifyDay: e.target.value }))
              }
            >
              <option value="1">Lunes</option>
              <option value="2">Martes</option>
              <option value="3">Miercoles</option>
              <option value="4">Jueves</option>
              <option value="5">Viernes</option>
              <option value="6">Sabado</option>
              <option value="0">Domingo</option>
            </select>
            <button
              onClick={() => {
                let hour = this.state.notifyHour;
                let day = this.state.notifyDay;

                this.notifyMe(hour, day);
              }}
            >
              Notificarme
            </button>
          </div>
        );
      }

      if (date.getDay() > lastAv.getDay()) {
        this.updateWeek(1);
        return LoadingComp;
      }

      this.checkAvailability(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      return LoadingComp;
    }

    if (!this.state.therapistInfo) {
      this.getTherapists();
      return LoadingComp;
    } else {
      availableWeekDays = this.getAvailableWeekDays(date);
    }

    let availability;

    if (!this.state.times) {
      this.checkAvailability(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    } else if (this.state.times.length > 0) {
      availability = (
        <div className="availability">
          {this.state.times.map((time) => {
            let convertedTime =
              time.time > 12 ? `${time.time - 12} pm` : `${time.time} am`;
            let className =
              time.time == this.state.time
                ? "time-option selected-time"
                : "time-option";
            return (
              <h1
                key={time.time}
                onClick={() => this.selectTime(time.time)}
                className={className}
              >
                {convertedTime}
              </h1>
            );
          })}
        </div>
      );
    } else {
      availability = (
        <p style={{ textAlign: "center", color: "gray" }}>
          Busca citas disponibles en otras fechas
        </p>
      );
    }

    let days = this.renderDayBadges(availableWeekDays);

    let cannotBackward = true;

    if (availableWeekDays && availableWeekDays.length > 0) {
      cannotBackward = today > availableWeekDays[0];
    }

    let dateInfo;
    if (availableWeekDays && availableWeekDays.length > 0) {
      let firstDay = availableWeekDays[0];
      let lastDay = availableWeekDays[availableWeekDays.length - 1];

      let from = `${monthNames[firstDay.getMonth()].slice(
        0,
        3
      )} ${firstDay.getDate()}`;
      let to = `${monthNames[lastDay.getMonth()].slice(
        0,
        3
      )} ${lastDay.getDate()}`;
      dateInfo = `${from} a ${to}`;
    }

    if (this.state.loading) {
      return LoadingComp;
    }

    let maxDayWidth = 20;
    let percent = 100 / availableWeekDays.length;
    if (percent > maxDayWidth) {
      percent = maxDayWidth;
    }
    let columnstemplate = `repeat(${availableWeekDays.length}, ${percent}%)`;

    // Discounts and packs modal render
    let cuponStyle = "";
    let cuponText = "Usar Cupon o Paquete";

    if (this.data.packId || this.data.cuponId) {
      cuponStyle = "pack-btn";
      let cuponData = this.data.entityData;
      if (this.data.packId && cuponData) {
        let discounts = cuponData.discounts ? cuponData.discounts.length : 0;
        let remaining = Number(cuponData.sessions) - discounts;
        cuponText = `${cuponData.service.name} ${remaining}/${cuponData.sessions}`;
      } else if (this.data.cuponId && cuponData) {
        cuponText = `Cupon por $${COP(cuponData.amount)} COP`;
      }
    }

    return (
      <>
        <div className="calendar animated-intro">
          {Intl.DateTimeFormat().resolvedOptions().timeZone ==
            "America/Bogota" && (
            <div className="online-selector">
              <div>
                <h1
                  onClick={() => this.setState(() => ({ location: "online" }))}
                  className={this.state.location == "online" && "selected"}
                >
                  Online
                </h1>
              </div>
              <div>
                <h1
                  onClick={() =>
                    this.setState(() => ({ location: "presencial" }))
                  }
                  className={this.state.location == "presencial" && "selected"}
                >
                  Presencial
                </h1>
              </div>
            </div>
          )}
          <div className="month-selector">
            <i
              className={`${cannotBackward ? "back-disabled" : ""}`}
              onClick={(e) => !cannotBackward && this.updateWeek(-1)}
            ></i>
            <div className="date-info">
              <p>Semana de</p>
              <h2>{dateInfo}</h2>
            </div>
            <i
              onClick={(e) => this.updateWeek(1, this)}
              className="forward"
            ></i>
          </div>
          <div
            className="days-grid"
            style={{ gridTemplateColumns: columnstemplate }}
          >
            {days}
          </div>
          <div>{availability}</div>
          <div className="nav-ops">
            {this.state.clientInfo &&
            (this.state.clientInfo.discounts || this.state.clientInfo.packs) ? (
              <p className={cuponStyle} onClick={() => this.showCupons()}>
                {cuponText}
              </p>
            ) : (
              ""
            )}
            <p className="next-btn" onClick={() => this.schedule()}>
              Agendar
            </p>
          </div>

          {this.state.confirm}
        </div>
        {this.state.modal}
      </>
    );
  }
}

class AppointmentsConfig extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
    this.state = {
      services: null,
      selectedService: null,
    };
  }

  render() {
    let detail;
    detail = this.data.service;

    return (
      <div className="appointments-config">
        <span
          className="icon icon-back"
          onClick={(e) =>
            this.root.setComponent(TherapyDetail, null, this.data.service)
          }
        ></span>
        <p>{detail.name}</p>
        <Calendar parent={this} data={this.data} root={this.root}></Calendar>
      </div>
    );
  }
}

export default AppointmentsConfig;
export { monthNames, dayNames, LoadingComp, PackOrCuponSelector };
