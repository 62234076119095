import React from "react";
import { BoldButton } from "../BoldButton";
import { getClientInfo } from "../../hooks/clientInfo";
import { formatter } from "../../dateutils/names";
import { Shop } from "../Shops";
import { Workshops } from "../../pages/Workshops";
import { Services } from "../../pages/Services";
import { Courses } from "../../pages/Courses";
import "./style.css";

async function generatePurchase(entityType, entityId) {
  // console.log(entityType + " " + entityId);
  let body = {
    entity_type: entityType,
    entity_id: entityId,
  };

  let req = await fetch("/api/v1/purchases", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (req.status == 201) {
    let reqData = await req.json();
    let purchasedata = reqData.data;
    return purchasedata;
  }
}

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {
      user: null,
      loaded: false,
      purchaseData: null,
    };
  }

  async generatePurchase() {
    let purchaseData = await generatePurchase(
      this.data.entityType,
      this.data.entityId
    );
    if (purchaseData) {
      this.setState(() => ({ purchaseData: purchaseData }));
    }
  }

  async getUserInfo() {
    let userInfo = await getClientInfo();
    if (Object.keys(userInfo).length > 0) {
      this.setState(() => ({ user: userInfo, loaded: true }));
    } else {
      window.open(
        `/login?section=checkout&entity=${this.data.entityType}&id=${this.data.entityId}&action=purchase`,
        "_self"
      );
    }
  }

  cancel() {
    if (this.data.entityType == "workshops") {
      this.root.setComponent(Workshops, null, this.data, null);
    }

    if (this.data.entityType == "services") {
      this.root.setComponent(Services, null, this.data, null);
    }

    if (this.data.entityType == "karts") {
      this.root.setComponent(Shop, null, this.data, null);
    }

    if (this.data.entityType == "courses") {
      this.root.setComponent(Courses, null, this.data, null);
    }
  }

  render() {
    if (!this.state.user && !this.state.loaded) {
      this.getUserInfo();
      return (
        <div className="checkout-page">
          <p>Cargando...</p>
        </div>
      );
    }

    if (!this.state.purchaseData && this.state.user) {
      this.generatePurchase();
      return (
        <div className="checkout-page">
          <p>Cargando...</p>
        </div>
      );
    }

    let deliveryInfo;

    console.log(this.state.user);

    let location;
    let address;

    if (
      this.state.user &&
      this.state.user.contact &&
      this.state.user.contact.city
    ) {
      location = `${this.state.user.contact.city} - ${this.state.user.contact.country}`;
    }

    if (
      this.state.user &&
      this.state.user.contact &&
      this.state.user.contact.address
    ) {
      address = this.state.user.contact.address;
    }

    if (this.data.entityType == "karts") {
      deliveryInfo = (
        <div className="delivery-info">
          <label>Datos de entrega</label>
          <p>
            <span>Ciudad/Pais:</span> {location}
          </p>
          <p>
            <span>Telefono:</span> {this.state.user.phone}
          </p>
          <label>Direccion</label>
          <input placeholder="Calle 43 # 23..." defaultValue={address}></input>
        </div>
      );
    }

    return (
      <div className="checkout-page">
        <img className="icon icon-back" onClick={() => this.cancel()}></img>
        <h1>Estas a punto de comprar</h1>
        {this.state.purchaseData.metadata.image ? (
          <img src={this.state.purchaseData.metadata.image}></img>
        ) : (
          ""
        )}
        <div
          style={{
            padding: "24px 0px 12px",
            boxSizing: "border-box",
            marginTop: "24px",
          }}
        >
          {this.state.purchaseData.metadata.list &&
            this.state.purchaseData.metadata.list.map((ent) => {
              return (
                <div className="checkout-product">
                  <img src={ent.image}></img>
                  <div>
                    <h1>{ent.name}</h1>
                    <p>{ent.units} units</p>
                  </div>
                  <h2>
                    {formatter.format(ent.prices[0].real_price * ent.units)}
                  </h2>
                </div>
              );
            })}
        </div>

        {this.state.purchaseData.metadata.name && (
          <h2>{this.state.purchaseData.metadata.name}</h2>
        )}

        {this.state.purchaseData.pay_link.shippingCosts ? (
          <div className="shipping-costs">
            <div>
              <h1>Total productos</h1>
              <p>
                {formatter.format(
                  this.state.purchaseData.pay_link.amount -
                    this.state.purchaseData.pay_link.shippingCosts -
                    900
                )}
              </p>
            </div>
            <div>
              <h1>Costos de Envio</h1>
              <p>
                {formatter.format(
                  this.state.purchaseData.pay_link.shippingCosts +
                  900
                )}
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        <BoldButton data={this.state.purchaseData.pay_link}></BoldButton>

        {deliveryInfo}

        {this.state.purchaseData.metadata.description && (
          <>
            <label>Descripcion</label>
            <p>{this.state.purchaseData.metadata.description}</p>
          </>
        )}
      </div>
    );
  }
}

export { Checkout };
