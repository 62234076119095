import React from "react";
import "./style.css";
import { formatter, newUUID } from "../../../dateutils/names";
import { ProductCard } from "..";

class ProductDetail extends ProductCard {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data.product;
    this.shoppingKart = props.data.shoppingKart;
    this.state = {};
  }

  openKart() {
    this.root.setState(() => ({ renderKart: true, renderProduct: false }));
  }

  render() {
    let count = 0;
    if (this.shoppingKart) {
      this.shoppingKart.list.forEach((prod) => {
        if (prod == this.data._id) {
          count += 1;
        }
      });
    }
    return (
      <div className="product-detail">
        <div className="shopping-cart-float" onClick={() => this.openKart()}>
          <img className="icon"></img>
          <p>
            {this.shoppingKart && this.shoppingKart.list
              ? this.shoppingKart.list.length
              : 0}
          </p>
        </div>

        <img src={this.data.image}></img>

        <div>
          <h1>{this.data.name}</h1>
          <p>{this.data.description}</p>

          {count == 0 ? (
            <h2>{formatter.format(this.data.prices[0].price)}</h2>
          ) : (
            <h3>{formatter.format(this.data.prices[0].price)}</h3>
          )}
          {count > 0 && (
            <h2>{formatter.format(this.data.prices[0].price * count)}</h2>
          )}
          {this.shoppingKart &&
          this.shoppingKart.list.includes(this.data._id) ? (
            <div className="kart-calculator">
              <button onClick={() => this.removeFromKart()}>-</button>
              <p>{count}</p>
              <button onClick={() => this.addToKart()}>+</button>
            </div>
          ) : (
            <button onClick={() => this.addToKart()}>Agregar al Carrito</button>
          )}
          <p
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            by{" "}
            <span style={{ fontWeight: "bold" }}>{this.data.owner_name}</span>
          </p>
          <div className="nav-ops">
            <p>Regresar a la tienda</p>
        </div>
        </div>
        
      </div>
    );
  }
}

export { ProductDetail };
