import React from "react";
import "./style.css";
import { APPO_STATUS_CODES } from "../../pages/Profile/constants";
import { sleep } from "../../App";
import { formatter, newUUID } from "../../dateutils/names";

const STATUS = ["APPROVED", "REJECTED", "FAILED"];


class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remaining: Number(props.seconds)
    }
  }

  async tick() {
    await sleep(1000);
    if (this.state.remaining > 0) {
      this.setState((p)=>({remaining: p.remaining - 1}));
    }
  }

  render() {
    this.tick();
    return <><span>{this.state.remaining}</span></>
  }
}

class ConfirmTransaction extends React.Component {
  constructor(props) {
    super(props);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.transactionID = urlParams.get("bold-order-id");
    this.state = {
      transactionState: null,
      tries: 0,
      maxTries: 4
    };
  }

  async retryConfirmation() {
    if (this.state.tries >= this.state.maxTries) {
      return;
    }
    await sleep(5000);
    this.checkStatus();
  }

  async checkStatus()   {
    let req = await fetch(`/api/v1/transactions?id=${this.transactionID}`);

    if (req.status == 200) {
      let state = await req.json();
      this.setState((p) => ({ transactionState: state, tries: p.tries + 1 }));
      // this.setState((p) => ({ transactionState: {status: 1, pay_link: {amount: 150000}}, tries: p.tries + 1 }));

    }
    // else {
    //     this.setState(()=>({transactionState: {status: "No transaction found"}}));
    // }
  }

  redirectToAsset() {
    // // // console.log(this.state.transactionState);
    let assetType = this.transactionID.split('_')[0];
    // console.log(this.state.transactionState);

    if (assetType == "appointments") {
      window.open(
        `/?section=appointment&id=${this.state.transactionState._id}`,
        "_self"
      );
    } else if (assetType == "courses") {
      let courseID = this.transactionID.split('_')[1];
      window.open(
        `/?section=courses&id=${courseID}`,
        "_self"
      );
    } else if(assetType == "purchases") {
      window.open(
        `/?section=${this.state.transactionState.entity_type}&id=${this.state.transactionState.entity_id}`,
        "_self"
      );
    } else {
      window.open(
        `/?section=packs&id=${this.state.transactionState._id}`,
        "_self"
      );
    }
    
  }

  render() {
    let content;
    if (!this.state.transactionState) {
      this.checkStatus();
    }
    let iconClass = "";

    if (this.state.transactionState && this.state.transactionState.status) {
      iconClass = APPO_STATUS_CODES.getByCode(
        this.state.transactionState.status
      ).style;

      if (
        this.state.transactionState.status ==
        APPO_STATUS_CODES.PAID_UNCONFIRMED.code
      ) {
        content = (
          <>
            <h1>{formatter.format(this.state.transactionState.pay_link.amount)}</h1>
            <h2>Pago Completo!</h2>
            <p>
              Muchas gracias por ayudarnos a crecer y brindar cada vez una mejor
              experiencia.
            </p>
            <p>
              Ahora puedes regresar, y empezar a disfrutar de lo que Psicologa Marion te ofrece
            </p>
            {this.state.transactionState && this.state.transactionState._id ? (
              <button onClick={() => this.redirectToAsset()}>
                Continuar
              </button>
            ) : (
              ""
            )}
          </>
        );
      } else if (
        this.state.transactionState.status == APPO_STATUS_CODES.PENDING_PAY.code
      ) {
        content = (
          <>
            <h2>Estamos esperando aprobacion de tu entidad bancaria</h2>

            {this.state.transactionState && this.state.transactionState._id ? (
              <button onClick={() => this.redirectToAsset()}>
                Regresar a Psicologa Marion
              </button>
            ) : (
              ""
            )}
          </>
        );
        this.retryConfirmation();
      } else if (
        this.state.transactionState.status ==
        APPO_STATUS_CODES.PAYMENT_FAILED.code
      ) {
        content = (
          <>
            <p>
              Lo sentimos tu transaccion no fue aprobada, porfavor intentalo
              nuevamente
            </p>

            {this.state.transactionState && this.state.transactionState._id ? (
              <button
                style={{ backgroundColor: "var(--primary-shadow)" }}
                onClick={() => this.redirectToAsset()}
              >
                Regresar a Psicologa Marion
              </button>
            ) : (
              ""
            )}
          </>
        );
      } else if (this.state.transactionState.status == APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code) {
        content = (
          <>
            <p>Transaccion pendiente, reintentaremos la confirmacion en <Countdown key={newUUID()} seconds={5}></Countdown> segundos</p>

            <h1>{formatter.format(this.state.transactionState.pay_link.amount)}</h1>
          </>
        )
        this.retryConfirmation();
      }
    }

    return (
      <div className="transaction-status">
        <img className={`icon icon-success-pay ${iconClass}`}></img>
        <p>
          {this.state.transactionState
            ? APPO_STATUS_CODES.getByCode(this.state.transactionState.status)
                .label
            : "Cargando informacion de tu transaccion"}
        </p>
        {content}
      </div>
    );
  }
}

export { ConfirmTransaction };
