async function saveAudio(file, oldSrc) {
    const data = new FormData();
    data.append("file", file);
    data.append("metadata", oldSrc);
    const resp = await fetch(
        '/api/v1/audios',
        {
            method: 'POST',
            body: data
        }
    )
    if (resp.status == 200) {
        const data = await resp.json();
        return data.audio_id;
    }
}


async function deleteAudio(id) {
    const resp = await fetch(
        `/api/v1/audios/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    if (resp.status == 200) {
        return;
    }
}

export {
    saveAudio,
    deleteAudio
}