import './style.css';
import React from 'react';

class AstroMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientInfo: null
        }
    }

    async clientInfo() {
        const resp = await fetch('/api/v1/users/info');
        if (resp.status == 200) {
            let json = await resp.json();
            this.setState(()=>({clientInfo: json.response}))
        } else {
            this.setState(()=>({clientInfo: {'error': 'no data'}}))
        }
    }

    render() {
        let content;
        if (!this.state.clientInfo) {
            this.clientInfo();
            content = <p>Cargando tu informacion</p>
        } else {
            let signClass = 'icon icon-'+ this.state.clientInfo.sign;
            content = (
                <>
                    <h1></h1>
                    <img className={signClass}></img>
                    <h1></h1>
                    <p>{this.state.clientInfo.sign}</p>
                </>
            )
        }
        return (
            <div className='astro-container'>
                {content}
            </div>
        )
    }
}

export {
    AstroMain
}