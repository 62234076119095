let vh = window.innerHeight * 0.01;
import {colorPalletes, setColorScheme, currentScheme } from './colors.js';



function setVariables() {
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  setColorScheme(currentScheme);
}

setVariables();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Main.css';
import './icons.css';
import { Main } from './App';
import Login, { Auth } from './pages/Login';
import './fonts/BrittanySignature.ttf';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Admin } from './pages/Admin/index.js';
import { ConfirmTransaction } from './components/ConfirmTransaction/index.js';
import { VideoCall } from './components/VideoCall/index.js';
import { SuperAdmin } from './pages/SuperAdmin/index.js';


const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main/>}/>
      <Route path="/login" element={<Login></Login>}/>
      <Route path="/auth" element={<Auth></Auth>}/>
      <Route path='/transactions' element={<ConfirmTransaction></ConfirmTransaction>}></Route>
      <Route path='/admin' element={<Admin></Admin>}></Route>
      <Route path='/call' element={<VideoCall></VideoCall>}></Route>
      <Route path='/management' element={<SuperAdmin></SuperAdmin>}></Route>
    </Routes>
  </BrowserRouter>
);
