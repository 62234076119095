import React from "react";
import { newUUID } from "../../../../../../dateutils/names";
import { VideoPreview } from "../../Preview";
import { BaseEditor } from "./base";
import { sleep } from "../../../../../../dateutils/names";

class VideoEditor extends BaseEditor {
  preview() {
    let data = { ...this.state };
    data["pageIndex"] = this.index;
    this.root.renderPreview(VideoPreview, data);
  }
  async saveVideo() {
    if (this.state.file) {
      this.setState(() => ({
        uploading: true,
        percent: "Uploading Video in chunks",
      }));

      const chunkSize = 4 * 1048576;

      let videoId = newUUID();

      // console.log("chunk size " + chunkSize);
      // console.log("file size " + this.state.file.size);

      for (let start = 0; start < this.state.file.size; start += chunkSize) {
        const chunk = this.state.file.slice(start, start + chunkSize);
        const fd = new FormData();
        fd.append("file", chunk);
        fd.append("video_id", videoId);
        // console.log(start);
        // console.log("waiting for chunk tp upload");
        const req = await fetch("/api/v1/videos/chunks", {
          method: "POST",
          body: fd,
        });

        if (req.status == 200) {
          let elapsed = Math.ceil(((start + chunkSize) * 100) / this.state.file.size);
          this.setState(() => ({
            percent: `${elapsed} % (${Math.ceil(
              (start + chunkSize) / 1048576
            )}/${Math.ceil(this.state.file.size / 1048576)}) MB`,
          }));
        } else {
          return;
        }

        // // console.log(req);
      }

      // console.log(videoId);

      let processData = {
        old: this.state.oldSrc,
        id: videoId,
      };
      this.setState(() => ({ percent: `Processing quality` }));

      const req = await fetch("/api/v1/videos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(processData),
      });

      this.setState(() => ({ percent: `Quality downgrade complete!` }));

      if (req.status == 200) {
        let data = await req.json();
        let src = data.video;

        this.setState(() => ({
          src: src,
          raw: null,
          file: null,
          uploading: false,
          snapshot: data.snapshot,
          percent: "0 %",
        }));
        await sleep(200);
        this.saveData(false);
        await sleep(200);
        this.root.saveModule(false);
      }
    } else {
      this.saveData(false);
      await sleep(200);
      this.root.saveModule(false);
    }
  }

  uploadVideo() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".mp4";
    input.onchange = (event) => {
      if (event.target.files[0]) {
        let file = event.target.files[0];
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadeddata = () => {
          window.URL.revokeObjectURL(video.src);
          component.setState((p) => ({
            src: null,
            oldSrc: p.src,
            raw: URL.createObjectURL(file),
            duration: video.duration,
            filename: file.name,
            file: file,
            snapshot: null,
          }));
        };
        video.src = URL.createObjectURL(file);
      }
    };
    input.click();
  }

  view() {
    if (this.state.collapsed) {
      let duration = this.state.duration;

      let dM = String(Math.floor(duration / 60)).padStart(2, "0");
      let dS = String(Math.floor(duration % 60)).padStart(2, "0");
      duration = `${dM}:${dS}`;
      return (
        <div className="collapsed-video-block">
          <img src={this.state.snapshot}></img>
          <p>Duracion {this.state.duration ? duration : "00:00"}</p>
        </div>
      );
    } else {
      return (
        <div className="video-block" key={newUUID()}>
          {this.state.uploading && (
            <div className="course-loading">
              <div className="gif">
                <p>Subiendo video</p>

                <img className="icon icon-course-loading"></img>
              </div>

              <p>{this.state.percent}</p>
            </div>
          )}
          <h2>Leccion en Video</h2>
          {this.state.src || this.state.raw ? (
            <div
              className="service-main-actions cover active"
              onClick={() => this.uploadVideo()}
            >
              {!this.state.snapshot && (
                <video>
                  <source
                    src={
                      this.state.src
                        ? `${this.state.src}#t=0.1`
                        : `${this.state.raw}#t=0.1`
                    }
                    type="video/mp4"
                  ></source>
                </video>
              )}

              {this.state.snapshot && <img src={this.state.snapshot}></img>}

              <p>Cambiar el video</p>
            </div>
          ) : (
            <div
              id="placeholder"
              className="service-main-actions cover"
              onClick={() => this.uploadVideo()}
            >
              <img className="icon icon-add-cover"></img>
              <p>Sube un video</p>
            </div>
          )}
          {(this.state.src || this.state.raw) && (
            <div className="input-video-details">
              <p className="input-video-filename">{this.state.filename}</p>
              <p className="input-video-duration">
                {Math.ceil(this.state.duration)}seg
              </p>
              {this.state.file && this.state.file.size && (
                <p className="input-video-duration">
                  {Math.ceil(this.state.file.size / 1048576)} MB
                </p>
              )}
            </div>
          )}
          <p>O ingresa una url</p>

          <label>URL</label>
          <br></br>
          <input
            value={this.state.src}
            onChange={(e) => this.setState(() => ({ src: e.target.value }))}
          ></input>
        </div>
      );
    }
  }
}

export { VideoEditor };
