import React from "react";

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
  }
}

export { BaseComponent };
