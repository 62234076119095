

// GoogleCalendarManager
import React from "react";
import './style.css';
import { dayNames, monthNames } from "../../Appointments";


let dayTemplate = {
    hours: [],
    enabled: false
}

class AvailabilityManager extends React.Component {
    constructor(props) {
        super(props);
        this.root = props.root;
        this.state = {
            component: null,
            compData: null,
            expanded: false,
            fromAPI: false,
            weekdays: dayNames.map((weekday, index)=>{
                const day = JSON.parse(JSON.stringify(dayTemplate));
                day.dayName = weekday;
                day.weekday = index;
                day.expanded = false;
                return (day);
            }),
            holidays: [],
            alert: null
        }
    }

    async getAdminAvailability() {
        const resp = await fetch('/api/v1/users/info');
        if (resp.status == 200) {
            const adminData = (await resp.json()).response;
            let availabilityData = {fromAPI: true};
            if (adminData.weekly_availability) {
                adminData.weekly_availability.forEach((av)=>{
                    av.expanded = false;
                })
                availabilityData.weekdays = adminData.weekly_availability;
                
            }
            if (adminData.holidays_and_timeoff) {
                availabilityData.holidays = adminData.holidays_and_timeoff;
            }
            this.setState(()=>(availabilityData))
        }
    }
    async updateAvailability() {
        await fetch(
            '/api/v1/users',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    weekly_availability: this.state.weekdays,
                    holidays_and_timeoff: this.state.holidays
                })
            }
        )
        this.getAdminAvailability();
    }

    addHourAtDay(day) {
        this.renderComponent(AvailabilityCreator, day);
    }

    addHoliday() {
        this.renderComponent(HolidayTimeOffCreator, null);
    }

    renderComponent(Component, data) {
        this.setState(()=>({
            component: Component,
            compData: data
        }))
    }

    toggle() {
        this.setState((p)=>({expanded: !p.expanded}));
    }

    enableDay(index) {
        this.setState((p)=>{
            let weekdays = p.weekdays;
            weekdays[index].enabled = !weekdays[index].enabled;
            return ({
                weekdays: weekdays
            })
        })
    }


    expandDay(index) {
        this.setState((p)=>{
            let weekdays = p.weekdays;
            weekdays[index].expanded = !weekdays[index].expanded;
            return ({
                weekdays: weekdays
            })
        })
    }

    updateWeek(index, data) {
        this.setState((p)=>{
            let weekdays = p.weekdays;
            weekdays[index].hours.push(data);
            return {
                weekdays: weekdays,
                component: null,
                compData: null
            }
        })
    }

    updateHolidays(index, data) {
        this.setState((p)=>{
            let holidays = p.holidays;
            if (index) {
                holidays[index - 1] = data;    
            } else {
                holidays.push(data);
            }
            
            return {
                holidays: holidays,
                component: null,
                compData: null
            }
        });
    }

    deleteHourAtDay(dayIndex, hourIndex) {
        this.setState((p)=>{
            let weekdays = p.weekdays;
            if (weekdays[dayIndex].hours.length > 1) {
                weekdays[dayIndex].hours.splice(hourIndex, 1);
            } else {
                weekdays[dayIndex].hours = [];
            }
            return {
                weekdays: weekdays
            }
        })
    }

    removeHoliday(index) {
        let comp = this;

        let callback = async ()=>{
            comp.setState((p)=>{
                let holidays = p.holidays;
                if (holidays.length > 1) {
                    holidays.splice(index, 1);
                } else {
                    holidays = [];
                }
                return {
                    holidays: holidays,
                    alert: null
                }
            });
        }
        this.setState(()=>({
            alert: {
                label: 'Deseas Eliminar este dia libre?',
                actions: [
                    {
                        label: 'Eliminar',
                        callback: callback
                    }
                ],
                color: 'var(--primary-shadow)'
            }
        }));
    }

    async closealert(now) {
        if (!now) {
            await sleep(10000);
        }
        this.setState(()=>({alert: null}));
    }


    render() {

        if (!this.state.fromAPI) {
            this.getAdminAvailability();
        }

        let componentView;

        if (this.state.component) {
            let Comp = this.state.component;
            componentView = <Comp root={this} data={this.state.compData}></Comp>
        }


        let content;
        if (this.state.expanded) {
            content = (
                <div className="availability-content">
                    <h2>Semanal</h2>
                    <p>Configura tus dias y horas diponibles</p>
                    {/* <div className="service-main-actions availability">
                        <h1>Dias disponibles</h1>
                        <button }>Agregar</button>
                    </div> */}
                    <div>
                        {
                            this.state.weekdays.map((day, index) => {
                                return(
                                    <div className={`availability-day-card ${day.enabled ? 'enabled-av-day' : day.expanded ? 'expanded-av-day' : ''}`}>
                                        <div className="availability-card-actions">
                                            <img className={`icon icon-radial ${day.enabled ? 'enabled-av-day' : ''}`} onClick={()=>this.enableDay(index)}></img>
                                        </div>
                                        <div className="dropdown-title">
                                            <h1>{day.dayName}</h1>
                                            <img className={`icon icon-dropdown ${day.enabled && !day.expanded ? 'enabled-av-day' : day.expanded && day.enabled ? 'expanded-enabled-av-day' : day.expanded ? 'expanded-av-day': '' }`}
                                                 onClick={()=>this.expandDay(index)}></img>
                                        </div>
                                        {
                                            day.expanded && (
                                                <>
                                                    
                                                    <div className="card-hours">
                                                        {
                                                            day.hours.map((hour, hourIndex)=>{
                                                                return (
                                                                    <div className={`hour-badge ${day.enabled ? 'enabled-av-day' : ''}`}>
                                                                        <p>{hour.hour}:{hour.minute} {hour.merid}</p>
                                                                        <img className="icon icon-delete-hour"
                                                                            onClick={()=>this.deleteHourAtDay(index, hourIndex)}
                                                                        ></img>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className={`hour-badge add ${day.enabled ? 'enabled-av-day' : ''}`}>
                                                            <img className="icon icon-add" onClick={()=>this.addHourAtDay(day)}></img>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <br></br>
                    <h2></h2>
                    <div className="service-main-actions">
                        <h2>Dias festivos y Vacaciones</h2>
                        <button onClick={()=>this.addHoliday()}>Agregar</button>
                    </div>
                    <div style={{marginTop: '12px'}}>
                        {
                            this.state.holidays.map((holiday, index)=>{
                                return (
                                    <div className="holiday-card">
                                        <div className="holiday-card-actions">
                                            <img className="icon icon-delete" onClick={()=>this.removeHoliday(index)}></img>
                                        </div>
                                        <h1>{monthNames[holiday.month]} {holiday.day} de {holiday.year}</h1>
                                        <p>{holiday.description}</p>
                                        
                                    </div>);
                            })
                        }
                    </div>
                    <p></p>
                </div>
            )
        }
        let alertView;

        if (this.state.alert) {
            alertView = (
                <div className='alert-container animated-intro' style={{backgroundColor: this.state.alert.color}}>
                    <img className='icon icon-cancel' onClick={()=>this.closealert(true)}></img>
                    <p>{this.state.alert.label}</p>
                    {
                        this.state.alert.actions ? (
                            <div>
                                {
                                    this.state.alert.actions.map((action)=>{
                                        return (
                                            <button onClick={()=>action.callback()}>{action.label}</button>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                    }
                </div>
            )
        }
        return (
            <div className={`availability-manager ${this.state.expanded ? 'expanded': ''}`}>
                { alertView }
                {
                    componentView
                }
                <div className={`service-main-actions availability-actions ${this.state.expanded ? 'expanded': ''}`} onClick={()=>this.toggle()}>
                    <h1>Disponibilidad</h1>
                    {
                        this.state.expanded ?
                        <button onClick={()=>this.updateAvailability()}>Guardar</button>
                        : <button style={{opacity: 0}}>blank</button>
                    }
                    
                    {/* <button onClick={()=>// // console.log('')}>action</button> */}
                </div>
                {   
                    this.state.expanded ? 
                    content : null
                }
            </div>
        )
    }
}

class AvailabilityCreator extends React.Component {
    constructor(props) {
        super(props);
        this.root = props.root;
        this.data = props.data;
        this.state = {
            weekday: this.data.weekday,
            hours: this.data.hours,
            data: {
                hour: 1,
                minute: 0,
                merid: 'am'
            }
        }
    }

    saveDay() {
        this.root.updateWeek(this.state.weekday, this.state.data);

    }

    updateData(param, value) {
        this.setState((p)=>{
            let data = p.data;
            data[param] = value;
            return {data: data}
        });
    }

    render() {

        let hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        let minutes = [0, 15, 30];
        return (
            <div className="availability-creator">
                <h1>{dayNames[this.state.weekday]}</h1>
                <p>Horas ya asignadas: {
                    this.state.hours.length > 0 ? 
                    this.state.hours.map((hour)=>{
                        return (
                            `${hour.hour}, `
                        )
                    }):
                    'ninguna'
                }</p>
                <div className="av-input-container">
                    <div>
                        <label>Hora</label>
                        <select defaultValue={this.state.data.hour}
                            onChange={(e)=>this.updateData('hour', e.target.value)}>
                            {
                                hours.map((hour)=>{
                                    return (
                                        <option value={hour}>{hour}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <br></br>
                        <p>:</p>
                    </div>
                    
                    <div>
                        <label>minuto</label>
                        <select defaultValue={this.state.data.minute}
                            onChange={(e)=>this.updateData('minute', e.target.value)}
                        >
                            {
                                minutes.map((minute)=>{
                                    return (
                                        <option value={minute}>{minute}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <label>am/pm</label>
                        <select defaultValue={this.state.data.merid}
                            onChange={(e)=>this.updateData('merid', e.target.value)}
                        >
                            <option value={'am'}>am</option>
                            <option value={'pm'}>pm</option>
                        </select>
                    </div>

                </div>
                <button className="save-day-hour" onClick={()=>this.saveDay()}>Guardar</button>
                <button onClick={()=>this.root.renderComponent(null, null)}>Cerrar</button>
            </div>
        );
    }
}

class HolidayTimeOffCreator extends React.Component {
    constructor(props) {
        super(props);
        this.root = props.root;
        this.data = props.data;
        this.state = {
            data: this.data ? 
                    this.data
                    : {
                        day: 1,
                        month: 0,
                        year: 2023,
                        description: ''
                    }
        }
    }

    saveDay() {
        this.root.updateHolidays(this.data ? this.data.index : null, this.state.data);
    }

    updateData(param, value) {
        this.setState((p)=>{
            let data = p.data;
            data[param] = value;
            return {data: data}
        });
    }

    render() {
        let months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        let years = [2023, 2024];
        return (
            <div className="availability-creator">
                <h1>Elige la fecha de tu descanso</h1>
                <p></p>
                <div className="holi-description">
                    <label>Descripcion</label>
                    <textarea value={this.state.data.description} onChange={(e)=>this.updateData('description', e.target.value)}></textarea>
                </div>
                <div className="holi-input-container">
                    <div>
                        <label>Dia</label>
                        <input defaultValue={this.state.data.day} value={this.state.data.day} onChange={(e)=>this.updateData('day', e.target.value)}></input>
                    </div>
                    <div>
                        <label>Mes</label>
                        <select defaultValue={this.state.data.month}
                            onClick={(e)=>this.updateData('month', e.target.value)}>
                            {
                                months.map((month)=> {
                                    return (
                                        <option value={month}>{monthNames[month]}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <label>Año</label>
                        <select defaultValue={this.state.data.year}
                            onClick={(e)=>this.updateData('year', e.target.value)}>
                            {
                                years.map((year)=> {
                                    return (
                                        <option value={year}>{year}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                </div>
                <button className="save-day-hour" onClick={()=>this.saveDay()}>Guardar</button>
                <button onClick={()=>this.root.renderComponent(null, null)}>Cerrar</button>
            </div>
        );
    }
}



export {
    AvailabilityManager
}