import React from "react";
import './style.css';
import { COP, formatter } from "../../dateutils/names";


class BoldButton extends React.Component {
    constructor(props) {
        super(props);
        let buttonData = props.data;
        this.elementReference = React.createRef();
        this.state = {
            signature: buttonData.signature,
            amount: buttonData.amount,
            orderId: buttonData.orderId,
            redirectionURL: buttonData.redirectionURL,
            apiKey: buttonData.apiKey
            // eventsURL: buttonData.eventsURL
        }
    }

    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "https://checkout.bold.co/library/boldPaymentButton.js";
        script.async = true;
        script.setAttribute('data-bold-button', "dark");
        script.setAttribute("data-order-id", this.state.orderId);
        script.setAttribute("data-currency", "COP");
        script.setAttribute("data-amount", this.state.amount);
        script.setAttribute("data-api-key", this.state.apiKey);
        script.setAttribute("data-integrity-signature", this.state.signature);
        script.setAttribute("data-redirection-url", this.state.redirectionURL);
        // script.setAttribute("data-notification-url", this.state.eventsURL);
    
        this.elementReference.current.appendChild(script);
    }

    render() {
        return(
            <div className="bold-button">
                <h1>{formatter.format(this.state.amount)}</h1>
                <div ref={this.elementReference} style={{display: "flex", alignItems: "center", justifyContent: "center"}}></div>
            </div>
        )
    }
}

export {
    BoldButton
}