import React from "react";
import "./App.css";
import "./Main.css";
import { mockServices } from "./constants.js";
import backImage from "./images/meditation.svg";
import profilePic from "./images/main-slider.jpg";
import { Services } from "./pages/Services/index.js";
import { Checkout } from "./components/Checkout/index.js";
import { Course, Courses } from "./pages/Courses/index.js";
import { Menu, menuOptions } from "./components/Menu/index.js";
import { colorPalletes, setColorScheme } from "./colors.js";
import Profile from "./pages/Profile/index.js";
import { getGlobalClientInfo } from "./hooks/clientInfo.js";
import { newUUID } from "./dateutils/names.js";
import { getSingleAppointment } from "./hooks/appointments.js";
import UserAppointment from "./pages/Profile/UserAppointment/index.js";
import {
  PackDetail,
  PacksAndDiscounts,
} from "./pages/Profile/PacksAndDiscounts/index.js";
import { getCourses } from "./hooks/courses.js";
import { getRandomUUID } from "./constants.js";
import { getWorkshops } from "./hooks/workshops.js";
import { WorkshopDetail } from "./pages/Workshops/WorkshopDetail/index.js";
import { getCurrentTherapistInfo } from "./hooks/therapists.js";
import { ExplorePage } from "./pages/Explore/index.js";
import TherapyDetail from "./pages/TherapyDetail/index.js";
import { Shop } from "./components/Shops/index.js";

export async function sleep(millsecs) {
  return new Promise((resolve) => setTimeout(resolve, millsecs));
}

class MainSlider extends React.Component {
  openMap() {
    window.open("https://goo.gl/maps/ZTPRpdba5JWaWQMQ8", "_blank");
  }

  render() {
    return (
      <div className="main-slider">
        <div></div>
      </div>
    );
  }
}

class ThemePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }

  render() {
    if (!this.state.toggle) {
      return (
        <div
          className="theme-selector"
          onClick={() => this.setState((p) => ({ toggle: !p.toggle }))}
        >
          <img className="icon icon-palette"></img>
        </div>
      );
    } else {
      return (
        <div className="theme-selector toggled">
          <div>
            <h1>Elige un Tema</h1>
            {colorPalletes.map((palette, index) => {
              return (
                <div
                  className="palette-list-item"
                  onClick={() => {
                    setColorScheme(index);
                    this.setState((p) => ({ toggle: !p.toggle }));
                  }}
                >
                  <h1>paleta</h1>
                  {Object.entries(palette).map(([k, value]) => {
                    return <p style={{ backgroundColor: value }}></p>;
                  })}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
}

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.queryparams = new URLSearchParams(window.location.search);
    this.state = {
      currentComponent: App,
      title: "Psicologa Marion",
      data: null,
      menu: null,
      noreload: false,
    };
  }

  setComponent(Component, title, data, menu) {
    if (Component === Services) {
      let index = 0;
      menuOptions().forEach((option, ind) => {
        if (option.label == "Terapias") {
          index = ind;
        }
      });
      menu = menuOptions()[index];
    }


    if ((Component === WorkshopDetail || Component === TherapyDetail || Component === Shop)) {
      let index = 0;
      menuOptions().forEach((option, ind) => {
        if (option.label == "Explorar") {
          index = ind;
        }
      });
      menu = menuOptions()[index];
    }
    this.setState((p) => ({
      currentComponent: Component,
      title: title,
      data: data || {},
      menu: menu || p.menu,
      noreload: true,
    }));
  }

  async clientInfo() {
    const clientInfo = await getGlobalClientInfo();
    let index = 0;
    menuOptions().forEach((option, ind) => {
      if (option.label == "Explorar") {
        index = ind;
      }
    });
    if (clientInfo && clientInfo.name) {
      this.setComponent(
        ExplorePage,
        clientInfo.professional_name,
        null,
        menuOptions()[index]
      );
    } else {
      this.setComponent(App, null, null, menuOptions()[index]);
    }
  }

  async openAppointment() {
    const appointmentId = this.queryparams.get("id");
    const appointment = await getSingleAppointment(appointmentId);

    if (appointment && appointment.length > 0) {
      this.setComponent(UserAppointment, "Detalle de Cita", appointment[0]);
    }
  }

  async openPacks() {
    const clientInfo = await getGlobalClientInfo();

    let packID = this.queryparams.get("id");
    let pack;

    clientInfo.packs.forEach((pa) => {
      if (pa._id == packID) {
        pack = pa;
      }
    });

    if (packID && pack) {
      this.setComponent(PackDetail, "Paquetes y Cupones", {
        discounts: clientInfo.discounts,
        packs: clientInfo.packs,
        pack: pack,
      });
    } else {
      this.setComponent(PacksAndDiscounts, "Paquetes y Cupones", {
        discounts: clientInfo.discounts,
        packs: clientInfo.packs,
        pack: pack,
      });
    }
  }

  async openCheckout() {
    // console.log(this.queryparams);
    let entity = this.queryparams.get("entity");
    let entityId = this.queryparams.get("id");

    // console.log(entity);
    // console.log(entityId);

    this.setComponent(Checkout, null, {
      entityType: entity,
      entityId: entityId,
    });
  }

  async openCourse(id) {
    let [courses, accData] = await getCourses();
    let course = [];
    courses.map((c) => {
      if (c._id == id) {
        course = c;
      }
    });
    course["courses"] = courses;
    this.setComponent(Course, null, course);
  }

  async openWorkshops(id) {
    let current = await getCurrentTherapistInfo();
    let [workshops, accData] = await getWorkshops(current._id);
    let workshop = [];
    workshops.map((w) => {
      if (w._id == id) {
        workshop = w;
      }
    });
    workshop["workshops"] = workshops;
    this.setComponent(WorkshopDetail, null, workshop);
  }

  render() {
    if (!this.state.noreload) {
      this.clientInfo();
      return <div></div>;
    }
    let Component = this.state.currentComponent;
    let menu = this.state.menu;

    // Section manager
    const section = this.queryparams.get("section");
    if (section == "profile") {
      Component = Profile;
      let index = 0;
      menuOptions().forEach((option, ind) => {
        if (option.label == "Perfil") {
          index = ind;
        }
      });
      menu = menuOptions()[index];
      this.queryparams.delete("section");
    } else if (section == "appointment") {
      this.queryparams.delete("section");
      this.openAppointment();
      return <></>;
    } else if (section == "packs") {
      this.queryparams.delete("section");
      this.openPacks();
    } else if (section == "courses") {
      let id = this.queryparams.get("id");
      this.queryparams.delete("section");
      this.queryparams.delete("id");
      this.openCourse(id);
    } else if (section == "workshops") {
      let id = this.queryparams.get("id");
      this.queryparams.delete("section");
      this.queryparams.delete("id");
      this.openWorkshops(id);
    } else if (section == "checkout") {
      let id = this.queryparams.get("id");
      this.queryparams.delete("section");
      this.openCheckout();
    }
    if (Component != App) {
      return (
        <div className="animated-intro">
          {/* <ThemePicker></ThemePicker> */}
          {/*{this.state.title && (
            <h1 className="title-dark">{this.state.title}</h1>
          )}*/}
          <Component
            root={this}
            data={this.state.data}
            key={newUUID()}
          ></Component>
          <Menu key={newUUID()} root={this} option={menu}></Menu>
        </div>
      );
    }
    return (
      <div>
        <Component root={this}></Component>
      </div>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.refs = {};

    this.root = props.root;

    this.state = {
      refs: this.refs,
      currentService: null,
      services: null,
    };
  }

  async showPosition(position, context) {
    const resp = await fetch("/api/v1/appointments/timezone", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        },
      }),
    });
    context.setState(() => ({ geoLocation: position }));
  }

  async updateService(index) {
    this.setState((p) => {
      return {
        currentService: mockServices[index],
      };
    });
  }

  openService(serviceId) {
    window.open(`/terapias?id=${serviceId}`, "_self");
  }

  render() {
    return (
      <div className="main-frame">
        <h1 className="title">Psicologa Marion</h1>
        <img className="icon index-main-picture"></img>

        <p className="sub-title">
          Te damos la bienvenida a este espacio de transformación enfocado en ti
        </p>
        <p className="high-title">Da el primer paso hacia el bienestar hoy.</p>
        <br></br>
        <button
          className="main-btn"
          onClick={() => this.root.setComponent(ExplorePage)}
        >
          Continuar
        </button>
        <br></br>
        <button
          className="second-btn"
          onClick={() => window.open("/login", "_self")}
        >
          Iniciar Sesion
        </button>
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  }
}

export { App, Main };
