import React from "react";
import {
  deleteWorkshop,
  getWorkshops,
  saveWorkshop,
} from "../../../hooks/workshops";
import { sleep } from "../../../App";
import "./style.css";
import { saveImage } from "../../../hooks/images";
import { dayNames, monthNames } from "../../Appointments";
import { formatter, newUUID, range } from "../../../dateutils/names";

class WorkshopManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.userInfo;
    this.state = {
      workshops: null,
      expanded: false,
      accData: null,
    };
  }

  async getWorkshops() {
    if (this.userInfo) {
      let [workshops, accData] = await getWorkshops(
        this.userInfo && this.userInfo._id
      );
      if (!workshops) {
        workshops = [];
      }
      this.setState(() => ({ workshops: workshops, accData: accData }));
    } else {
      await sleep(1000);
      this.setState(() => ({ workshops: null }));
    }
  }

  async toggle() {
    if (!this.state.workshops) {
      await this.getWorkshops();
      await sleep(100);
    }
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  createNewWorkshop() {
    this.root.renderComponent(WorkshopCreator, null);
  }

  updateWorkshop(Workshop) {
    this.root.renderComponent(WorkshopCreator, Workshop);
  }

  render() {
    // if (!this.state.workshops) {
    //     this.getWorkshops();
    // }

    let workshopsView;

    if (this.state.workshops) {
      workshopsView = this.state.workshops.map((workshop) => {
        return (
          <div
            className="admin-workshop-detail"
            onClick={() => this.updateWorkshop(workshop)}
          >
            <div>
              {workshop.image && (
                <img className="icon" src={workshop.image}></img>
              )}
            </div>
            <div>
              <h1>{workshop.name}</h1>

              {/* <p className="description">{Workshop.description}</p> */}
              <p>{workshop._id}</p>
              <p>
                <span style={{ fontWeight: "bold" }}>Planes: </span>
                {workshop.plans.length}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Duracion: </span>
                {workshop.time} minutos
              </p>
            </div>
          </div>
        );
      });
    }
    return (
      <div
        className={`workshop-manager ${this.state.expanded ? "expanded" : ""}`}
      >
        <div className="service-main-actions" onClick={() => this.toggle()}>
          {this.state.expanded ? (
            <>
              <h1>Talleres</h1>
              <div>
                <button onClick={() => this.createNewWorkshop()}>
                  Agregar
                </button>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Talleres</h1>
            </>
          )}
        </div>
        {this.state.expanded ? workshopsView : null}
        {this.state.expanded && this.state.accData ? (
          <div className="chart-bars" key={newUUID()}>
            <h1>Meta: {this.state.accData.max}</h1>
            <p>Talleres vendidos</p>
            {this.state.accData.metrics.map((metric) => {
              return (
                <div className="chart-bar">
                  <p>
                    {metric.name} ({metric.units})
                  </p>
                  <div className="bar">
                    <p style={{ width: `${metric.percent}%` }}></p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

class WorkshopCreator extends React.Component {
  constructor(props) {
    super(props);

    this.root = props.root;
    this.data = props.data;
    this.owner = props.owner;
    this.plan_template = {
      price: null,
      sessions: null,
      usd_price: null,
    };

    let data = props.data || {};

    let inputdate = data.date
      ? new Date(data.date.year, data.date.month, data.date.day)
      : new Date();
    // {
    //   month: birthDate.getMonth() + 1,
    //   year: birthDate.getFullYear(),
    //   day: birthDate.getDate(),
    //   hour: hour
    // }

    this.state = {
      id: data._id || null,
      name: data.name || null,
      description: data.description || null,
      plans: data.plans || [],
      tempPlan: null,
      time: data.time || 60,
      imageData: data.image || null,
      location: data.location || null,
      planEditor: false,
      index: null,
      public: data.public || false,
      alert: null,
      date: data.date || null,
      inputDate: {
        date: inputdate,
        edit: false,
      },
    };
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      let contents = ev.target.result;
      component.setState(() => ({ imageData: contents }));
      // component.setState(() => ({ tones: tones }));
    };
    reader.readAsDataURL(file);
  }

  uploadCover() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));
      let imageURL = await saveImage(event.target.files[0], this.state.src);
      component.setState(() => ({ imageData: imageURL, uploading: false }));
      await sleep(1000);
      this.saveWorkshop();
    };
    input.click();
  }

  updatetempPlan(field, value) {
    this.setState((p) => {
      let tempPlan = p.tempPlan;
      if (!tempPlan) {
        tempPlan = {};
      }
      tempPlan[field] = value;
      return {
        tempPlan: tempPlan,
      };
    });
    this.saveWorkshop();
  }

  updateDate(year, month, day) {
    this.setState((p) => {
      let mo = month ? month : p.inputDate.date.getMonth();
      let ye = year ? year : p.inputDate.date.getFullYear();
      let da = day ? day : p.inputDate.date.getDate();
      return {
        inputDate: {
          edit: true,
          date: new Date(ye, mo, da),
        },
      };
    });
  }

  saveDate() {
    this.setState((p) => {
      let mo = p.inputDate.date.getMonth();
      let ye = p.inputDate.date.getFullYear();
      let da = p.inputDate.date.getDate();
      let options = p.inputDate;
      options.edit = false;
      return {
        date: {
          year: ye,
          month: mo,
          day: da,
        },
        inputDate: options,
      };
    });
    this.saveWorkshop();
  }

  async saveWorkshop() {
    let workshopTemplate = {
      name: this.state.name,
      description: this.state.description,
      time: this.state.time,
      plans: this.state.plans,
      image: this.state.imageData,
      id: this.data ? this.data._id : this.state.id,
      location: this.state.location,
      owner: this.owner,
      public: this.state.public,
      date: this.state.date,
    };

    // // console.log(workshopTemplate);
    // let stop;

    // Object.entries(workshopTemplate).map(([key, value]) => {
    //   if (!value && key != "id") {
    //     stop = true;
    //   }
    // });
    // if (stop) {
    //   return;
    // }

    let id = await saveWorkshop(workshopTemplate);
    // console.log(id);
    if (!this.state.id) {
      // console.log("update id");
      this.setState(() => ({ id: id }));
    }

    // this.root.renderComponent(null, null);
  }

  close() {
    this.root.renderComponent(null, null);
  }

  renderPlanEditor() {
    // // console.log("render plan editor");
    let planData = Object.assign({}, this.state.tempPlan);
    return (
      <div className="plan-editor-container">
        <div className="service-main-actions plan-editor">
          <h1>Editor de plan</h1>
          <button
            onClick={() => {
              this.setState((p) => {
                let plans = p.plans;
                if (p.index) {
                  plans[p.index - 1] = planData;
                } else {
                  plans.push(planData);
                }
                return {
                  planEditor: false,
                  plans: plans,
                  tempPlan: null,
                  index: null,
                };
              });
              this.saveWorkshop();
            }}
          >
            Guardar
          </button>
        </div>
        <br></br>
        <label>Precio</label>
        <input
          type="number"
          onChange={(e) => this.updatetempPlan("price", e.target.value)}
          value={planData.price || undefined}
        ></input>
        <br></br>
        {/* <label>Precio en USD</label>
        <input
          type="number"
          onChange={(e) => this.updatetempPlan("usd_price", e.target.value)}
          value={planData.usd_price || undefined}
        ></input>
        <br></br>
        <label>Precio en EUR</label>
        <input
          type="number"
          onChange={(e) => this.updatetempPlan("eur_price", e.target.value)}
          value={planData.eur_price || undefined}
        ></input> */}
        <button
          onClick={() =>
            this.setState(() => ({ planEditor: false, tempPlan: null }))
          }
        >
          Cerrar
        </button>
        {/* <p>Los precios en otras divisas se calculan en base a la tasa de cambio vigente al momento de agendar la cita.</p> */}
      </div>
    );
  }

  renderPlanCard(index, plan) {
    return (
      <div className="creator-plan-card">
        <div>
          <h1>{plan.sessions}</h1>
          <p>
            Precio :{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatter.format(plan.price)}
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              con tarifa {formatter.format(plan.price, true)}
            </span>
          </p>
          {/* <p>
            Precio en USD:{" "}
            <span style={{ fontWeight: "bold" }}>${plan.usd_price}</span>
          </p>
          <p>
            Precio en EUR:{" "}
            <span style={{ fontWeight: "bold" }}>${plan.eur_price}</span>
          </p> */}
        </div>
        <div
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="icon icon-delete"
            onClick={() => this.deletePlan(index)}
          ></img>
          <img
            className="icon icon-edit"
            onClick={() =>
              this.setState(() => ({ tempPlan: plan, index: index }))
            }
          ></img>
        </div>
      </div>
    );
  }

  async deleteWorkshop() {
    const root = this.root;
    const id = this.data._id;
    let callback = async () => {
      await deleteWorkshop(id);
      root.renderComponent(null, null);
    };
    this.setState(() => ({
      alert: {
        label: "Deseas Eliminar este taller?",
        actions: [
          {
            label: "Eliminar",
            callback: callback,
          },
        ],
        color: "var(--primary-shadow)",
      },
    }));
  }

  deletePlan(planIndex) {
    let comp = this;

    let callback = async () => {
      comp.setState((p) => {
        let plans = p.plans;
        plans.splice(planIndex, 1);
        if (plans.length == 1) {
          plans = [];
        }
        return {
          plans: plans,
          alert: null,
        };
      });
      comp.saveWorkshop();
    };
    this.setState(() => ({
      alert: {
        label: "Deseas Eliminar este plan?",
        actions: [
          {
            label: "Eliminar",
            callback: callback,
          },
        ],
        color: "var(--primary-shadow)",
      },
    }));
  }

  async closealert(now) {
    if (!now) {
      await sleep(10000);
    }
    this.setState(() => ({ alert: null }));
  }

  renderDatePicker() {
    return (
      <div className="input-birth">
        <div className="birth-option">
          <p>Dia</p>
          <select
            defaultValue={this.state.inputDate.date.getDate()}
            onChange={(e) => this.updateDate(null, null, e.target.value)}
          >
            {range(1, 31).map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <div className="birth-option">
          <p>Mes</p>
          <select
            defaultValue={this.state.inputDate.date.getMonth()}
            onChange={(e) => this.updateDate(null, e.target.value, null)}
          >
            {monthNames.map((month, index) => {
              return (
                <option key={month} value={index}>
                  {month}
                </option>
              );
            })}
          </select>
        </div>
        <div className="birth-option">
          <p>Año</p>
          <select
            defaultValue={this.state.inputDate.date.getFullYear()}
            onChange={(e) => this.updateDate(e.target.value, null, null)}
          >
            {range(new Date().getFullYear(), new Date().getFullYear() + 1)
              .reverse()
              .map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>
        <button onClick={() => this.saveDate()}>Guardar</button>
      </div>
    );
  }

  render() {
    let datePicker;

    if (this.state.inputDate.edit) {
      datePicker = this.renderDatePicker();
    } else {
      let time = this.state.inputDate.date;
      let date = `${dayNames[time.getDay()]}, ${time.getDate()} ${
        monthNames[time.getMonth()]
      } ${time.getFullYear()}`;

      datePicker = (
        <div>
          <label>Fecha</label>
          <p
            onClick={() =>
              this.setState((p) => {
                let options = p.inputDate;
                options.edit = true;
                return { inputDate: options };
              })
            }
          >
            {date}
          </p>
        </div>
      );
    }
    return (
      <div className="workshop-creator">
        {this.state.alert && this.state.alert && (
          <div
            className="alert-container animated-intro"
            style={{ backgroundColor: this.state.alert.color }}
          >
            <img
              className="icon icon-cancel"
              onClick={() => this.closealert(true)}
            ></img>
            <p>{this.state.alert.label}</p>
            {this.state.alert.actions ? (
              <div>
                {this.state.alert.actions.map((action) => {
                  return (
                    <button onClick={() => action.callback()}>
                      {action.label}
                    </button>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
        {(this.state.planEditor || this.state.tempPlan) &&
          this.renderPlanEditor()}
        <div className="service-main-actions save-workshop">
          {/* <img
            className="icon icon-back back-workshops"
            onClick={() => {
              this.saveWorkshop();
              this.close();
            }}
          ></img> */}
          <h1>Editor de Taller</h1>
          <button
            onClick={() => {
              this.saveWorkshop();
              this.close();
            }}
          >
            Guardar
          </button>
        </div>
        <div className="editor-content">
          {this.state.imageData ? (
            <div
              className={
                "service-main-actions cover " +
                (this.state.uploading ? "" : "active")
              }
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img src={this.state.imageData}></img>
              )}
              <p>
                {this.state.uploading ? "Subiendo imagen" : "Cambiar la imagen"}
              </p>
            </div>
          ) : (
            <div className="service-main-actions cover">
              <div>
                <img className="icon icon-add-cover"></img>
              </div>

              <p onClick={() => this.uploadCover()}>Agregar imagen</p>
            </div>
          )}

          {!this.state.public && (
            <div className="publish-header">
              <p>Sin publicar</p>
              <button
                className="public"
                onClick={() => {
                  this.setState(() => ({ public: true }));
                  this.saveWorkshop();
                }}
              >
                Publicar
              </button>
            </div>
          )}

          {this.state.public && (
            <div className="publish-header">
              <p>Publicado</p>
              <button
                onClick={() => {
                  this.setState(() => ({ public: false }));
                  this.saveWorkshop();
                }}
              >
                Regresar a Borrador
              </button>
            </div>
          )}

          <label>Nombre</label>
          <input
            value={this.state.name || ""}
            placeholder="..."
            onChange={(e) => {
              this.setState(() => ({ name: e.target.value }));
              this.saveWorkshop();
            }}
          ></input>

          <br></br>

          <label>Ubicacion</label>
          <input
            value={this.state.location || ""}
            placeholder="..."
            onChange={(e) => {
              this.setState(() => ({ location: e.target.value }));
              this.saveWorkshop();
            }}
          ></input>

          <br></br>

          {datePicker}

          <label>Descripcion</label>
          <textarea
            value={this.state.description || ""}
            onChange={(e) => {
              this.setState(() => ({ description: e.target.value }));
              this.saveWorkshop();
            }}
          ></textarea>
          <br></br>
          <label>Duracion</label>
          <select
            defaultValue={this.state.time || 60}
            onChange={(e) => {
              this.setState(() => ({ time: e.target.value }));
              this.saveWorkshop();
            }}
          >
            <option value={60}>1 hora</option>
            <option value={120}>2 horas</option>
            <option value={180}>3 horas</option>
          </select>
          <br></br>
          <div>
            {(!this.state.plans || this.state.plans.length == 0) && (
              <div className="service-main-actions plan-creator">
                <h1>Planes</h1>
                <button
                  onClick={() =>
                    this.setState(() => ({ planEditor: true, tempPlan: {} }))
                  }
                >
                  Agregar
                </button>
              </div>
            )}
            <div>
              {this.state.plans.map((plan, index) => {
                return this.renderPlanCard(index + 1, plan);
              })}
            </div>
          </div>
          <div className="nav-ops">
            <p
            className="extra-option"
              onClick={() => {
                this.saveWorkshop();
                this.close();
              }}
            >
              Guardar y Salir
            </p>
            {this.data && (
              <p
                className="accent-option"
                onClick={() => this.deleteWorkshop()}
              >
                Borrar Taller
              </p>
            )}
            <p onClick={() => this.root.renderComponent(null, null)}>Salir</p>
          </div>
        </div>
      </div>
    );
  }
}

export { WorkshopCreator, WorkshopManager };
