let colorPalletes = [
    // 0
    {
      primary: '#EFC69A',
      primaryShadow: '#ad5017',
      second: '#2e82f0',
      secondShadow: '#16509c',
      accent: '#31a99d',
      neutral: '#7c7c7c',
      text: '#3e3629'
    },
    // 1
    {
      primary: '#f4a261',
      primaryShadow: '#e76f51',
      second: '#2a9d8f',
      secondShadow: '#264653',
      accent: '#91CCD9',
      neutral: '#A1A1A1',
      text: '#767065'
    },
    // 2
    {
      primary: '#BC8DA0',
      primaryShadow: '#A04668',
      second: '#7EA172',
      secondShadow: '#31493C',
      accent: '#43AA8B',
      neutral: '#A1A1A1',
      text: '#767065'
    },
    // 3
    {
      primary: '#fcc897',
      primaryShadow: '#e29145',
      second: '#fcc897',
      secondShadow: '#855c3f',
      accent: '#6e8483',
      neutral: '#A1A1A1',
      text: '#767065'
    },
  
]

let currentScheme = 0;

function setColorScheme(index) {
  currentScheme = index;
    let schema = colorPalletes[index];
    let de = document.documentElement;
    de.style.setProperty('--primary', `${schema.primary}`);
    de.style.setProperty('--primary-shadow', `${schema.primaryShadow}`);
    de.style.setProperty('--second', `${schema.second}`);
    de.style.setProperty('--second-shadow', `${schema.secondShadow}`);
    de.style.setProperty('--text', `${schema.text}`);
    de.style.setProperty('--accent', `${schema.accent}`);
    de.style.setProperty('--neutral', `${schema.neutral}`);
  }

export {
    colorPalletes,
    setColorScheme,
    currentScheme
}