import React from "react";

import "./style.css";
import "../Profile/styles.css";
import {
  getAppointments,
  getAppointmentsAdmin,
} from "../../hooks/appointments";
import { APPO_STATUS_CODES, countryCodes } from "../Profile/constants";
import { ProfileEditor } from "./ProfileManager";
import { ServiceManager } from "./ServiceManager";
import { WorkshopManager } from "./Workshops";
import { ProductManager } from "./Products";
import { GoogleCalendarManager } from "./GoogleCalendar";
import { AvailabilityManager } from "./AvailabilityManager";
import { monthNames, dayNames } from "../Appointments";
import { sleep } from "../../App";
import { newUUID } from "../../dateutils/names";
import { CourseManager } from "./Courses";
import { Accounting } from "./Accounting";
import { Orders } from "./Orders";
import { SearchList } from "../../components/Search";
import { getCities } from "../../hooks/clientInfo";

class Template extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="animated-intro"></div>;
  }
}

class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.data;
    this.addressIndex = props.index;

    let data;

    if (this.addressIndex !== null) {
      data = this.userInfo.shipping_information.addresses[this.addressIndex];
    } else {
      data = {
        dial_code: "+57",
        country: "Colombia",
        city: "Cali",
        address: "Calle del gato # 34",
      };
    }

    this.state = {
      cities: null,
      data: data,
    };
  }

  async deleteOne() {
    let data = this.userInfo.shipping_information || { addresses: [] };

    if (this.addressIndex !== null) {
      data.addresses.splice(this.addressIndex, 1);
    }

    this.root.saveData(data);
  }

  close() {
    this.root.setState(() => ({ addressIndex: null, addAddress: false }));
  }

  async saveData() {
    let data = this.userInfo.shipping_information || { addresses: [] };

    if (this.addressIndex !== null) {
      data.addresses[this.addressIndex] = this.state.data;
    } else {
      data.addresses.push(this.state.data);
    }

    // console.log(data);
    this.root.saveData(data);
  }

  async updateAddress(key, result) {
    let cities;
    let countryName;
    if (key == "dial_code") {
      // console.log("dialcode changed");
      // console.log();
      let country = countryCodes.filter((c) => c.dial_code == result)[0];
      countryName = country.name;
      cities = await getCities(country.name);
    }

    this.setState((p) => {
      let data = { ...p.data };
      data[key] = result;

      if (countryName) {
        data["country"] = countryName;
      }
      // console.log("updated data");
      // console.log(data);
      return { data: data, cities: cities ? cities : p.cities };
    });
  }

  async getCities() {
    let cities = await getCities(this.state.data.country);
    this.setState(() => ({ cities: cities }));
  }

  render() {
    if (!this.state.cities) {
      this.getCities();
      return <></>;
    }
    return (
      <div className="address-input" key={"address-input"}>
        <div className="options">
          {this.addressIndex !== null && (
            <img
              className="icon icon-delete"
              onClick={() => this.deleteOne()}
            ></img>
          )}
          <img className="icon icon-save" onClick={() => this.saveData()}></img>
          <img className="icon icon-close" onClick={() => this.close()}></img>
        </div>
        <SearchList
          key={newUUID()}
          root={this}
          keys={["dial_code", "name"]}
          value="dial_code"
          callback="updateAddress"
          list={countryCodes}
          label="Pais"
          default={this.state.data.dial_code}
        ></SearchList>
        <SearchList
          key={newUUID()}
          list={this.state.cities}
          label="Ciudad"
          root={this}
          value="city"
          callback="updateAddress"
          default={this.state.data.city}
        ></SearchList>
        <label>Direccion</label>
        <input
          defaultValue={this.state.data.address}
          onChange={(e) => this.updateAddress("address", e.target.value)}
        ></input>
      </div>
    );
  }
}

class ShipmentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.userInfo;
    this.state = {
      expanded: false,
      addAddress: false,
      addressIndex: null,
      userInfo: this.userInfo,
    };
  }

  async getUserInfo() {
    const resp = await fetch("/api/v1/users/info");
    if (resp.status == 200) {
      const data = await resp.json();
      return data.response;
    } else if (resp.status == 401) {
      window.open("/login", "_self");
    }
  }

  async saveData(data) {
    await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shipping_information: data }),
    });
    let userInfo = await this.getUserInfo();
    this.setState(() => ({ userInfo: userInfo, addAddress: false }));
  }

  toggle() {
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  render() {
    let content;

    // console.log(this.state.userInfo);

    if (this.state.expanded) {
      content = (
        <div className="shipping-address-content">
          {this.state.userInfo.shipping_information &&
            !this.state.addAddress && (
              <div className="address-card-container">
                {this.state.userInfo.shipping_information.addresses.map(
                  (address, index) => {
                    return (
                      <div className="address-card">
                        <div>
                          <p>{address.city}</p>
                          <p>{address.country}</p>
                          <p>{address.address}</p>
                        </div>
                        <img
                          className="icon"
                          onClick={() => {
                            this.setState(() => ({
                              addAddress: true,
                              addressIndex: index,
                            }));
                          }}
                        ></img>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          {this.state.addAddress && (
            <AddressInput
              root={this}
              data={this.state.userInfo}
              index={this.state.addressIndex}
            ></AddressInput>
          )}
          {!this.state.addAddress && (
            <button
              onClick={() =>
                this.setState(() => ({ addAddress: true, addressIndex: null }))
              }
            >
              Agregar
            </button>
          )}
        </div>
      );
    }
    return (
      <div className={`shipping-info ${this.state.expanded ? "expanded" : ""}`}>
        <div
          className="service-main-actions"
          onClick={() => {
            if (!this.state.expanded) {
              this.toggle();
            }
          }}
        >
          {this.state.expanded ? (
            <>
              <h1>Direcciones de Recogida</h1>
              <div onClick={() => this.toggle()}>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Direcciones de Recogida</h1>
            </>
          )}
        </div>
        {content}
      </div>
    );
  }
}

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      alertData: null,
      userInfo: null,
    };
  }

  async getUserInfo() {
    const resp = await fetch("/api/v1/users/info");
    if (resp.status == 200) {
      const data = await resp.json();
      this.setState(() => ({ userInfo: data.response }));
    } else if (resp.status == 401) {
      window.open("/login", "_self");
    } else {
      console.log(resp);
    }
  }

  async renderComponent(Component, data) {
    if (!Component && !data) {
      await this.getUserInfo();
    }
    await sleep(300);
    this.setState(() => ({ alert: Component, alertData: data }));
  }

  render() {
    let component;

    if (!this.state.userInfo) {
      this.getUserInfo();
      return <></>;
    }

    if (this.state.alert) {
      let DComponent = this.state.alert;
      component = (
        <DComponent
          root={this}
          owner={this.state.userInfo._id}
          data={this.state.alertData}
        ></DComponent>
      );
    }
    return (
      <div className="admin-container" key={newUUID()}>
        {component && <div className="component-container">{component}</div>}

        <div className="content-box">
          {this.state.userInfo && (
            <>
              <div className="admin-nav">
                <ProfileEditor></ProfileEditor>
              </div>
              <h1>Admin Panel</h1>

              <AdminDates root={this}></AdminDates>

              <Accounting
                key={newUUID()}
                root={this}
                userInfo={this.state.userInfo}
              ></Accounting>

              {/* <h2>Ordenes de Compra</h2>

              <Orders
                key={newUUID()}
                root={this}
                userInfo={this.state.userInfo}
              ></Orders> */}

              <h2>Configuracion</h2>

              <AvailabilityManager
                root={this}
                userInfo={this.state.userInfo}
              ></AvailabilityManager>

              {/* <ShipmentInfo
                root={this}
                userInfo={this.state.userInfo}
              ></ShipmentInfo> */}

              <GoogleCalendarManager
                key={newUUID()}
                root={this}
                userInfo={this.state.userInfo}
              ></GoogleCalendarManager>

              <h2>Productos y Servicios</h2>

              <ServiceManager
                key={newUUID()}
                root={this}
                userInfo={this.state.userInfo}
              ></ServiceManager>
              <WorkshopManager
                key={newUUID()}
                root={this}
                userInfo={this.state.userInfo}
              ></WorkshopManager>
              {/* <ProductManager
                key={newUUID()}
                root={this}
                userInfo={this.state.userInfo}
              ></ProductManager> */}
              <CourseManager
                key={newUUID()}
                root={this}
                userInfo={this.state.userInfo}
              ></CourseManager>
            </>
          )}
          <div className="nav-ops">
            <p className="extra-option" onClick={() => window.open("/management", "_self")}>
              Super Admin
            </p>
            <p onClick={() => window.open("/", "_self")}>Salir</p>
          </div>
        </div>
      </div>
    );
  }
}

class AdminDates extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {
      appointments: null,
      expanded: true,
      date: new Date(),
    };
  }

  updateDate(offset) {
    this.setState((p) => {
      p.date.setDate(p.date.getDate() + offset);
      return { date: p.date, appointments: null };
    });
  }

  async getAppointments() {
    let appointments = await getAppointmentsAdmin(this.state.date);
    await sleep(800);
    this.setState(() => ({ appointments: appointments }));
  }

  toggle() {
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  dateDetail(appointment) {
    this.root.renderComponent(DateDetail, appointment);
  }

  render() {
    if (!this.state.appointments) {
      this.getAppointments();
    }

    let dates;

    if (this.state.appointments) {
      dates = this.state.appointments.map((appointment) => {
        return (
          <div
            className="date-item"
            onClick={() => this.dateDetail(appointment)}
          >
            <div>
              <h1>{appointment.user.name || appointment.user.email}</h1>
              <p>{appointment.time} am</p>
            </div>
            <div className="actions">
              <img className="icon icon-history"></img>
              <img className="icon icon-meet"></img>
            </div>
          </div>
        );
      });
    } else {
      dates = <p className="loading-text">cargando...</p>;
    }

    if (dates.length == 0) {
      dates = <p className="loading-text">No hay citas agendadas</p>;
    }

    let content;

    let currentDate = `${
      dayNames[this.state.date.getDay()]
    } ${this.state.date.getDate()} de ${
      monthNames[this.state.date.getMonth()]
    } de ${this.state.date.getFullYear()}`;

    if (this.state.expanded) {
      content = (
        <>
          <br></br>

          <div className="week-selector">
            <p onClick={() => this.updateDate(-1)}>anterior</p>
            <p onClick={() => this.updateDate(1)}>siguiente</p>
          </div>

          <p className="date-display">{currentDate}</p>

          <div className="dates-list">
            <div className="weekday-container">{dates}</div>
          </div>
        </>
      );
    }

    return (
      <div className={`admin-dates ${this.state.expanded ? "expanded" : ""}`}>
        <div className="service-main-actions" onClick={() => this.toggle()}>
          {this.state.expanded ? (
            <>
              <h1>Citas Agendadas</h1>
              <div>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Citas Agendadas</h1>
            </>
          )}
        </div>
        {content}
      </div>
    );
  }
}

class DateDetail extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {
      data: props.data,
    };
  }
  close() {
    this.root.renderComponent(null, null);
  }
  render() {
    let betaTag = <span className="beta-tag">( beta )</span>;
    return (
      <div className="date-detail">
        <h1>Usuario</h1>
        <div className="user-detail">
          <h1>{this.state.data.user.name}</h1>
          <p>{this.state.data.user.email}</p>
        </div>
        <h1>Detalle de la sesion</h1>
        <div className="session-detail">
          <h1>{this.state.data.service.name}</h1>
          <p>
            <span style={{ fontWeight: "bold" }}>Dia:</span>{" "}
            {this.state.data.date.year}-{this.state.data.date.month}-
            {this.state.data.date.day}{" "}
            <span style={{ fontWeight: "bold" }}>Hora:</span>{" "}
            {this.state.data.time}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Timezone:</span>{" "}
            {this.state.data.timezone}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Ubicacion:</span>{" "}
            {this.state.data.location}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>ID: </span>
            {this.state.data._id}
          </p>
          <br></br>
          <p>
            <span style={{ fontWeight: "bold" }}>Informacion de pago:</span>
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Id del link: </span>
            {this.state.data.pay_link.code}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Estado:</span>
          </p>
          <h2
            className={`pay-status ${
              APPO_STATUS_CODES.getByCode(this.state.data.status).style
            }`}
          >
            {APPO_STATUS_CODES.getByCode(this.state.data.status).label}
          </h2>
          <button>Copiar link de pago</button>
          <button className="extra" onClick={() => this.close()}>
            Regresar al Admin
          </button>
          <button className="extra">Cambiar fecha {betaTag}</button>
          <button className="extra">Enviar Regalo {betaTag}</button>
          <button className="extra">Certificado de sesion {betaTag}</button>
          <button className="extra">Citas Previas {betaTag}</button>
          <button className="extra">Historia clinica {betaTag}</button>
        </div>
      </div>
    );
  }
}

export { Admin };
