import React from "react";
import "./style.css";
import { TherapistList } from "../Therapists";
import { getProducts } from "../../hooks/products";
import { formatPrice, formatter } from "../../dateutils/names";
import { newUUID } from "../../dateutils/names";
import { ProductCard, CartProduct } from "../Products";
import { Checkout } from "../Checkout";
import { ProductDetail } from "../Products/ProductDetail";
import { currentScheme } from "../../colors";

class Kart extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.products = props.data.products;
    this.kart = props.data.kart;
    this.state = {};
  }

  async checkout() {
    await this.root.updateKart(this.kart.list, true);
  }

  render() {
    let products = this.products.filter((data) =>
      this.kart.list.includes(data._id)
    );
    return (
      <div className="shopping-kart-detail">
        <h1>Carrito de Compras</h1>
        <br></br>
        <div>
          {products.map((product) => {
            return (
              <CartProduct
                key={newUUID()}
                root={this.root}
                data={product}
                kart={this.kart}
              ></CartProduct>
            );
          })}
        </div>
        <div className="kart-price">
          <p>Total (sin gastos de envio)</p>
          <h1>{formatter.format(this.kart.total * (1 + 0.0399 + 0.015))}</h1>
          {/* <p>
            {formatter.format(this.kart.total, true)}
          </p> */}
        </div>
        <p>Los costos de envio se agregaran en el checkout</p>

        <div className="nav-ops">
          <p
            className="accent-option"
            onClick={() => this.root.setState(() => ({ renderKart: false }))}
          >
            Seguir Comprando
          </p>

          <p className="extra-option" onClick={() => this.checkout()}>
            Continuar al pago
          </p>
        </div>
      </div>
    );
  }
}

class Shop extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    let id = localStorage.getItem("therapist");
    this.state = {
      products: null,
      therapist: id,
      loaded: false,
      shoppingKart: null,
      kartLoaded: false,
      renderKart: false,
      renderProduct: false,
      currentProduct: null,
    };
  }

  async getKart(commit = true) {
    let req = await fetch(`/api/v1/kart`);
    if (req.status == 200) {
      let data = await req.json();

      if (commit) {
        this.setState(() => ({
          shoppingKart: data.response,
          kartLoaded: true,
        }));
      } else {
        return data.response;
      }
    }
  }

  async updateKart(kart, invoice = false) {
    let req = await fetch(`/api/v1/kart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ list: kart, invoice: invoice }),
    });
    let updatedKart = await this.getKart(false);

    if (invoice) {
      this.root.setComponent(Checkout, null, {
        entityType: "karts",
        entityId: updatedKart._id,
      });
    } else {
      this.setState(() => ({ shoppingKart: updatedKart }));
    }
  }

  async addToKart(product) {
    let component = this;
    let kart = [...this.state.shoppingKart.list];
    kart.push(product._id);
    await component.updateKart(kart);
  }

  async removeFromKart(product) {
    let component = this;
    let kart = [...this.state.shoppingKart.list];
    let productIndex = kart.findIndex((prod) => prod == product._id);

    kart.splice(productIndex, 1);

    await component.updateKart([...kart]);
  }

  async getTherapists() {
    let therapists = await getTherapists();
    this.setState(() => ({ therapist: therapists[0]._id }));
  }

  async toggleFavorite(productID) {
    await fetch("/api/v1/users/favorites", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ entity_id: productID }),
    });
    this.loadProducts();
  }

  async setTherapist(therapist) {
    let therapists = await getGlobaltherapists();

    this.setState((p) => {
      return { therapist: therapist, products: null, therapists: therapists };
    });
  }

  async loadProducts() {
    const [products, accData] = await getProducts(null);
    this.setState(() => ({ products: products, loaded: true }));
  }

  async openKart() {
    if (this.state.products && this.state.shoppingKart) {
      this.setState(() => ({ renderKart: true }));
    }
  }

  render() {
    if (!this.shoppingKart && !this.state.kartLoaded) {
      this.getKart();
      return <div></div>;
    }

    if (!this.state.products && !this.state.loaded) {
      this.loadProducts();
    }

    if (this.state.renderKart) {
      return (
        <div>
          <Kart
            key={newUUID()}
            root={this}
            data={{
              products: this.state.products,
              kart: this.state.shoppingKart,
            }}
          ></Kart>
        </div>
      );
    } else if (this.state.renderProduct) {
      return (
        <div>
          <ProductDetail
            key={newUUID()}
            root={this}
            data={{
              ...this.state.currentProduct,
              shoppingKart: this.state.shoppingKart,
            }}
          ></ProductDetail>
        </div>
      );
    }

    return (
      <div className="shop">
        {/* <TherapistList root={this}></TherapistList> */}
        <br></br>
        <h1>Tienda de utilidades</h1>
        <div className="shopping-cart-float" onClick={() => this.openKart()}>
          <img className="icon"></img>
          <p>
            {this.state.shoppingKart && this.state.shoppingKart.list
              ? this.state.shoppingKart.list.length
              : 0}
          </p>
        </div>
        <p></p>
        <br></br>
        <div className="products-container">
          {this.state.products &&
            this.state.products.map((product) => {
              return (
                <ProductCard
                  key={newUUID()}
                  root={this}
                  data={product}
                  kart={this.state.shoppingKart}
                ></ProductCard>
              );
            })}
          {!this.state.products && this.state.loaded ? (
            <p>Aun no hay productos disponibles</p>
          ) : this.state.products ? (
            ""
          ) : (
            <p>Cargando productos...</p>
          )}
        </div>
      </div>
    );
  }
}

export { Shop };
