import React from "react";
import Profile from "..";
import "./style.css";
import AppointmentsConfig from "../../Appointments";
import { APPO_STATUS_CODES } from "../constants";
import { BoldButton } from "../../../components/BoldButton";
import { COP } from "../../../dateutils/names";
import { getGlobalClientInfo } from "../../../hooks/clientInfo";

class PackDetail extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.pack = this.data.pack;
    this.root = props.root;
    this.state = {
      alert: null,
      alertColor: "green",
      alertActions: null,
    };
  }

  scheduleWithPack() {
    this.root.setComponent(AppointmentsConfig, null, {
      service: this.pack.service,
      packId: this.pack._id,
      entityData: this.pack,
    });
  }

  async closealert(now = false) {
    if (!now) {
      await sleep(30000);
    }
    this.setState(() => ({ alert: null }));
  }

  async discountTransform(id) {
    let callback = async () => {
      const req = await fetch("/api/v1/discounts/transform", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          source: "packs",
          id: id,
        }),
      });
      if (req.status == 200) {
        getGlobalClientInfo(true);
        window.open("/?section=packs", "_self");
      }
    };
    this.setState(() => ({
      alert: "Quieres convertir el disponible de tu paquete en un cupon?",
      alertColor: "var(--warning)",
      alertActions: [
        {
          label: "Convertir a Cupon",
          callback: callback,
        },
      ],
    }));
  }

  async deletePack(id) {
    const resp = await fetch(`/api/v1/packs/${id}`, {
      method: "DELETE",
    });
    if (resp.status == 200) {
      const data = await resp.json();
      return data;
    }
  }

  async delete() {
    const id = this.pack._id;
    const root = this.root;
    const comp = this;
    let callback = async () => {
      let response = await comp.deletePack(id);
      if (!response) {
        comp.setState(() => ({
          alert:
            "No puedes cancelar esta cita mientras este en proceso de pago",
          alertColor: "var(--primary-shadow)",
          alertActions: null,
        }));
      } else {
        let clientinfo = await getGlobalClientInfo(true);

        root.setComponent(PacksAndDiscounts, null, {
          discounts: clientinfo.discounts,
          packs: clientinfo.packs,
        });
      }
    };
    this.setState(() => ({
      alert: "Quieres eliminar este paquete?",
      alertColor: "var(--primary-shadow)",
      alertActions: [
        {
          label: "Borrar Paquete",
          callback: callback,
        },
      ],
    }));
  }

  render() {
    let pack = this.pack;
    let discounts = 0;
    let badges = [];

    if (pack.discounts) {
      discounts = pack.discounts.length;
    }
    for (let i = 0; i < pack.sessions; i++) {
      badges.push(
        <img
          src={pack.service.image}
          className={i + 1 <= discounts ? "disabled-discount" : ""}
        ></img>
      );
    }

    let remaining = pack.sessions - discounts;
    return (
      <div className="pack-detail">
        {this.state.alert && (
          <div
            className="alert-container animated-intro"
            style={{ backgroundColor: this.state.alertColor }}
          >
            <img
              className="icon icon-cancel"
              onClick={() => this.closealert(true)}
            ></img>
            <pre>{this.state.alert}</pre>
            {this.state.alertActions ? (
              <div>
                {this.state.alertActions.map((action) => {
                  return (
                    <button
                      style={{ color: `${this.state.alertColor} !important` }}
                      onClick={() => action.callback()}
                    >
                      {action.label}
                    </button>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}

        <h1>
          Tienes<br></br>
          {remaining}
          <span>/{pack.sessions}</span>
        </h1>
        <div className="packs-badges detail">{badges}</div>
        <p>
          Sesiones de:<br></br>
          <span style={{ fontWeight: "bold" }}>{pack.service.name}</span>
        </p>
        {pack.status == APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code ? (
          <BoldButton data={pack.pay_link}></BoldButton>
        ) : (
          ""
        )}
        <div className="nav-ops">
          {remaining > 0 ? (
            <p
              style={{
                backgroundColor: "var(--primary-shadow)",
                color: "white",
              }}
              onClick={() => this.scheduleWithPack()}
            >
              Agendar Cita
            </p>
          ) : (
            ""
          )}

          {pack.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code &&
          remaining > 0 ? (
            <p
              onClick={() => {
                // console.log("");
              }}
            >
              Transferir Cupo
            </p>
          ) : null}

          {pack.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code ? (
            <p onClick={() => this.discountTransform(pack._id)}>
              Convertir a bono
            </p>
          ) : null}

          {pack.status == APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code ? (
            <p onClick={() => this.delete()}>Eliminar Paquete</p>
          ) : (
            ""
          )}
          <p
            onClick={() =>
              this.root.setComponent(PacksAndDiscounts, null, this.data)
            }
          >
            Regresar
          </p>
        </div>
      </div>
    );
  }
}

class CuponDetail extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.cupon = props.data.cupon;
    this.root = props.root;
  }

  async close() {}

  render() {
    return (
      <div className="cupon-detail animated-intro">
        <img className="icon"></img>
        <p>Cupon por</p>
        <h1>$ {COP(this.cupon.amount)} COP</h1>
        {/* <div className="split-cupon">
            <img className="icon"></img>
                <input placeholder={this.cupon.amount}></input>
            <img className="icon"></img>
        </div> */}
        <br></br>
        <div className="cupon-code">
          <p>{this.cupon.code}</p>
          <img
            className="icon"
            onClick={() => {
              navigator.share({
                title:
                  "Hola!, Quiero compartir este regalo contigo.\nIngresa al enlace y sigue todos los pasos para usar tu regalo.",
                text: "",
                url: `/redeem?code=${this.cupon.code}`,
              });
            }}
          ></img>
        </div>
        <p>Comparte como codigo</p>
        <br></br>
        <div className="nav-ops">
          {/* Go Back button */}
          <p
            onClick={() =>
              this.root.setComponent(PacksAndDiscounts, null, this.data)
            }
          >
            Regresar
          </p>
        </div>
      </div>
    );
  }
}

class PacksAndDiscounts extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
    this.state = {
      discounts: this.data.discounts || [],
      packs: this.data.packs || [],
    };
  }

  packDetails(pack) {
    this.root.setComponent(PackDetail, "", {
      ...this.data,
      pack: pack,
    });
  }

  cuponDetails(cupon) {
    this.root.setComponent(CuponDetail, "", {
      ...this.data,
      cupon: cupon,
    });
  }

  render() {
    return (
      <div className="packs-and-discounts animated-intro">
        {/* <span
          className="icon icon-back"
          onClick={(e) => {
            this.root.setComponent(Profile, "Espacio Personal");
          }}
        ></span> */}
        <h1>Cupones</h1>
        <div className="discounts">
          {this.state.discounts.map((discount) => {
            return (
              <div
                className="discount-card"
                onClick={() => this.cuponDetails(discount)}
              >
                <div className="discounts-container">
                  <img className="icon icon-gift"></img>
                </div>

                <p>{COP(discount.amount)} COP</p>
              </div>
            );
          })}
        </div>

        <div className="packs">
          <h1>Paquetes</h1>
          {this.state.packs.map((pack) => {
            let discounts = 0;
            let badges = [];

            if (pack.discounts) {
              discounts = pack.discounts.length;
            }
            for (let i = 0; i < pack.sessions; i++) {
              badges.push(
                <img
                  src={pack.service.image}
                  className={i + 1 <= discounts ? "disabled-discount" : ""}
                ></img>
              );
            }

            let remaining = pack.sessions - discounts;

            return (
              <div
                className={`pack-card ${remaining == 0 ? "disable" : ""}`}
                onClick={() => this.packDetails(pack)}
              >
                <h1>
                  {remaining}
                  <span>/{pack.sessions}</span>
                </h1>
                <p>Sesiones de {pack.service.name}</p>
                <div className="packs-badges">{badges}</div>
              </div>
            );
          })}
        </div>
        <div className="nav-ops">
          <p
            onClick={(e) => {
              this.root.setComponent(Profile, "Espacio Personal");
            }}
          >
            Regresar
          </p>
        </div>
      </div>
    );
  }
}

export { PacksAndDiscounts, PackDetail };
