import codes from '../../dateutils/codes.json';
import timezones from '../../dateutils/timezones.json';

class Status {
    constructor(statusCode, statusLabel, statusStyle) {
        this.code = statusCode;
        this.label = statusLabel;
        this.style = statusStyle;
    }
}


let APPO_STATUS_CODES = {
    UNPAID_UNCONFIRMED: new Status(1, 'Por Pagar y Confirmar', 'unpaid_unconfirmed'),
    PENDING_PAY: new Status(2, 'Esperando Aprobacion', 'pending_pay'),
    PAID_UNCONFIRMED: new Status(3, 'Por Confirmar', 'paid_unconfirmed'),
    PAID_CONFIRMED: new Status(4, 'Confirmada', 'paid_confirmed'),
    PAID_CANCELED: new Status(5, 'Cancelada', 'paid_canceled'),
    PAYMENT_FAILED: new Status(6, 'Pago Rechazado', 'paid_failed'),
}

APPO_STATUS_CODES['getByCode'] = (code) => {
    let status;
    Object.entries(APPO_STATUS_CODES).map(([key, value])=>{
        if (code == value.code) {
            status = key;
        }
    })
    return APPO_STATUS_CODES[status];
}

const mockSchedules = [
    {
        id: '1234',
        date: {
            month: 3,
            day: 1,
            year: 2023,
            time: 10,
            weekDay: 1
        },
        service: {
            id: '1234',
            name: 'Sesion Psicoterapia',
        },
        status: APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code

    },
    {
        id: '1235',
        location: 'presencial',
        date: {
            month: 4,
            day: 2,
            year: 2023,
            time: 11,
            weekDay: 5
        },
        service: {
            id: '1223',
            name: 'Constelacion Individual',
        },
        status: APPO_STATUS_CODES.PAID_CANCELED.code

    },
    {
        id: '1235',
        location: 'online',
        date: {
            month: 4,
            day: 2,
            year: 2023,
            time: 11,
            weekDay: 5
        },
        service: {
            id: '1223',
            name: 'Constelacion Individual',
        },
        status: APPO_STATUS_CODES.PAID_CANCELED.code

    },
    {
        id: '1235',
        location: 'online',
        date: {
            month: 4,
            day: 2,
            year: 2023,
            time: 11,
            weekDay: 5
        },
        service: {
            id: '1223',
            name: 'Constelacion Individual',
        },
        status: APPO_STATUS_CODES.PAID_CANCELED.code

    }

]


const countryCodes = codes;
const timezonesContries = timezones;

export { mockSchedules, countryCodes, timezonesContries, APPO_STATUS_CODES };