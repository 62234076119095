import React from "react";
import "./styles.css";
import "../../fonts/BrittanySignature.ttf";
import { sleep } from "../../App";
import { SearchList } from "../../components/Search";
import { countryCodes, timezonesContries } from "../Profile/constants";
import { newUUID } from "../../dateutils/names";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let country = timezonesContries.filter((tm) =>
      tm.timezones.includes(timezone)
    )[0].name;
    let dial_code = countryCodes.filter((c) => country == c.name)[0].dial_code;

    this.state = {
      email: null,
      phone: null,
      password: null,
      error: null,
      next: null,
      dial_code: dial_code,
    };
  }
  async auth() {
    const queryParams = new URLSearchParams(window.location.search);

    const resp = await fetch(`/api/v1/auth/login?` + queryParams, {
      mode: "no-cors",
      redirect: "follow",
    });
    if (resp.status == 200) {
      const data = await resp.json();
      window.open(data.url, "_self");
    }
  }

  async signIn() {
    let phone = this.state.phone;
    // add country code to phone;
    phone = `${this.state.dial_code} ${phone}`;
    const queryParams = new URLSearchParams(window.location.search);

    const resp = await fetch(`/api/v1/auth/login?` + queryParams, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: phone,
      }),
    });
    if (resp.status == 200) {
      const data = await resp.json();
      window.open(data.url, "_self");
    } else {
      this.setState(() => ({ error: "This phone doesn't exists" }));
    }
  }

  async updateDialCode(key, value) {
    this.setState(() => {
      let state = {};
      state[key] = value;
      return state;
    });
  }

  loginMethod() {
    return (
      <div className="login">
        {this.state.error && <p className="error-text">{this.state.error}</p>}
        <p className="login-title" onClick={() => window.open("/", "_self")}>
          Psicologa Marion
        </p>
        <img className="icon login-icon"></img>
        <p>Te damos la bienvenida a tu espacio personal</p>
        <SearchList
          key={newUUID()}
          root={this}
          callback="updateDialCode"
          label="Codigo Pais"
          value="dial_code"
          keys={["dial_code", "name"]}
          list={countryCodes}
          default={this.state.dial_code || dial_code}
        ></SearchList>
        <div className="search-list">
          <p>Telefono</p>
          <input
            type="number"
            defaultValue={this.state.phone}
            onChange={(e) => this.setState(() => ({ phone: e.target.value }))}
          ></input>
        </div>

        <br></br>
        <button onClick={() => this.signIn()}>Ingresar</button>
        {/* <h2>
          Esta Version local permite acceso a funciones de administrador y
          cliente
        </h2> */}
      </div>
    );
  }

  prodRender() {
    return (
      <div className="login">
        <p className="login-title" onClick={() => window.open("/", "_self")}>
          Psicologa Marion
        </p>
        <img></img>
        <p>Te damos la bienvenida a tu espacio personal</p>
        <div className="login-btn" onClick={() => this.auth()}>
          <img className="icon icon-google"></img>
          <p>Ingresa con Google</p>
        </div>

        <h2>
          Al ingresar aceptas nuestra<br></br>Política de privacidad y datos
          personales
        </h2>
      </div>
    );
  }

  render() {
    return this.loginMethod();
    // if (location.host.includes(":3000")) {
    //   return this.localRender();
    // } else {
    //   return this.prodRender();
    // }
  }
}

export class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {
      digits: [null, null, null, null, null, null],
    };
  }

  async codeValidation() {
    await sleep(200);
    const url = new URL(window.location.href);

    let code = this.state.digits.join("");

    const resp = await fetch(`/api/v1/auth${url.search}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ validation_code: code }),
    });

    if (resp.status != 500 && resp.status != 401) {
      let redirectUrl = (await resp.json()).redirect;
      window.open(redirectUrl, "_self");
      // window.open('/?section=profile', '_self');
    }
  }

  async focusElement(index) {
    await sleep(100);
    // console.log(window.navigator)
    // if ("virtualKeyboard" in window.navigator) {
    //   try {
    //     window.navigator.virtualKeyboard.overlaysContent = true;
    //     window.navigator.virtualKeyboard.show();
    //   } catch (e) {
    //     alert(e);
    //     console.log(e);
    //   }
    // } else {
    //   console.log("no virtual keyboard in navigator")
    //   alert("no virtual keyboard in navigator")
    // }
    let element = document.getElementById(`input-${index}`);
    if (element) element.focus();
    // }

    let indexes = [0, 1, 2, 3, 4, 5];

    for (let ind of indexes) {
      let element_rem = document.getElementById(`input-shadow-${ind}`);

      if (element_rem) element_rem.style.borderColor = "rgb(190, 190, 190)";
    }

    let element_sec = document.getElementById(`input-shadow-${index}`);

    if (element_sec) element_sec.style.borderColor = "var(--second)";
  }

  addDigit(e, index) {
    let digit;

    if (e) {
      digit = e.nativeEvent.data;
      console.log(e.nativeEvent.data);
    } else {
      digit = null;
    }

    this.setState((p) => {
      let digits = [...p.digits];
      if (digit === null || digit === "") {
        digit = null;
      }
      console.log(digit);
      if (digit !== null) {
        digit = Number(digit);
      }

      let nextDigit;
      let oldDigit = digits[index];

      console.log(`old digit ${oldDigit}`);
      console.log(oldDigit === null);
      console.log(digit === null);
      if (digit !== null) {
        nextDigit = index + 1;
      } else if (oldDigit === null && digit === null) {
        nextDigit = index - 1;
      } else {
        nextDigit = index;
      }

      console.log(nextDigit);

      if (nextDigit !== undefined) {
        this.focusElement(nextDigit);
      }

      digits[index] = digit;

      console.log(`index ${index} digits ${digits} digit ${digit}`);

      return { digits: digits };
    });
  }

  handledeleteInput(e, index) {
    console.log(e);
    if (e.key == "Backspace") {
      this.addDigit(null, index);
    }
  }

  render() {
    // this.authUser();
    return (
      <div className="auth-screen">
        <h1 className="title-dark">Psicologa Marion</h1>
        <p>Ingresa el codigo que enviamos a tu WhatsApp.</p>
        <div className="code-input">
          {this.state.digits.map((digit, index) => {
            return (
              <div className="code-input-wrapper">
                <input
                  // contenteditable
                  // virtualkeyboardpolicy="manual"
                  // tabIndex="0"
                  id={`input-${index}`}
                  type="number"
                  value={digit}
                  onKeyDown={(e) => this.handledeleteInput(e, index)}
                  onClick={(e) => this.focusElement(index)}
                  onChange={(e) => this.addDigit(e, index)}
                >
                  {/* {digit} */}
                </input>
                <p id={`input-shadow-${index}`}>{digit}</p>
              </div>
            );
          })}
        </div>

        <button onClick={() => this.codeValidation()}>Validar codigo</button>
      </div>
    );
  }
}
