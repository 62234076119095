import React from "react";
import { sleep } from "../../App";
import "./style.css";
import TherapyDetail from "../TherapyDetail";
import { getWorkshops } from "../../hooks/workshops";
import { TherapistList } from "../../components/Therapists";
import { getTherapists } from "../../hooks/therapists";
import { getGlobaltherapists } from "../../hooks/therapists";
import { WorkshopDetail } from "./WorkshopDetail";
import { dayNames, monthNames } from "../Appointments";

class Workshops extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {
      workshops: null,
      index: 0,
      therapist: null,
    };
  }

  async getTherapists() {
    let therapists = await getGlobaltherapists();
    // // console.log(therapists);

    if (!therapists || therapists.length <= 0) {
      return;
    }

    this.setState(() => ({
      therapist: therapists[0]._id,
      therapists: therapists,
    }));
  }

  async setTherapist(therapist) {
    let therapists = await getGlobaltherapists();

    this.setState((p) => {
      return { therapist: therapist, workshops: null, therapists: therapists };
    });
  }

  async toggleFavorite(workshopId) {
    await fetch("/api/v1/users/favorites", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ entity_id: workshopId }),
    });
    this.loadWorkshops();
  }

  async loadWorkshops() {
    let [workshops, accData] = await getWorkshops(this.state.therapist);
    let publicWorkshops = [];
    if (!workshops) {
      this.loadWorkshops();
      return;
    }
    workshops.forEach((workshop) => {
      if (workshop.public) {
        publicWorkshops.push(workshop);
      }
    });
    this.setState(() => ({ workshops: publicWorkshops }));
  }

  render() {
    let workshopsView;

    if (!this.state.workshops) {
      this.loadWorkshops();
    } else if (this.state.workshops) {
      workshopsView = this.state.workshops.map((workshop, index) => {
        let time = new Date(
          workshop.date.year,
          workshop.date.month,
          workshop.date.day
        );
        let date = `${dayNames[time.getDay()]}, ${time.getDate()} ${
          monthNames[time.getMonth()]
        } ${time.getFullYear()}`;
        return (
          <div
            key={workshop._id}
            className="workshop-card animated-intro"
            onTouchEnd={(e) => this.setState(() => ({ index: index }))}
          >
            <img src={workshop.image} />

            <img
              className={`icon icon-fav-product workshops ${
                workshop.favorite ? "full" : ""
              }`}
              onClick={() => this.toggleFavorite(workshop._id)}
            />
            
            <h1>{workshop.name}</h1>

            <h2>{date}</h2>
            
            <p>{workshop.description}</p>
            <h3
              onClick={() =>
                this.root.setComponent(WorkshopDetail, null, workshop)
              }
            >
              Ver más
            </h3>
          </div>
        );
      });
    }
    return (
      <>
        {/* <TherapistList root={this}></TherapistList> */}
        <div className="workshops">
          <p>Proximos Talleres</p>
          <div className="workshops-selector">{workshopsView}</div>
        </div>
      </>
    );
  }
}

export { Workshops };
