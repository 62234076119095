import React from "react";
import "./style.css";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
  }

  async callback(option) {
    this.data.callback(option);
  }

  async labelCallback() {
    this.data.labelCallback();
  }

  render() {
    console.log(this.data.list);
    return (
      <div className="carousel-container">
        <h1 onClick={()=>this.labelCallback()}>{this.data.label}</h1>
        <div className="scroll-container">
          {this.data.list.map((element) => {
            return (
              <div className="carousel-item" onClick={()=>this.callback(element)}>
                <img src={element.image}></img>
                <h1>{element.name}</h1>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export { Carousel };
