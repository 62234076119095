import React from "react";
import { VideoPreview } from "../Admin/Courses/ModuleEditor/Preview";
import { BlockPreview } from "../Admin/Courses/ModuleEditor/Preview";
import { QuizPreview } from "../Admin/Courses/ModuleEditor/Preview";
import { ModuleManager } from "./Modules";
import {
  getEnrolledCourses,
  getEnrolledCoursesProgress,
  updateEnrolledCoursesProgress,
} from "../../hooks/courses";

class MainCourseProgress extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {
      component: null,
      data: null,
      currentModule: 0,
      maxModule: 0,
      courseProgress: null,
    };
  }

  async getCourseData() {
    let data = await getEnrolledCoursesProgress(this.data._id);
    if (data) {
        this.setState(()=>({courseProgress: data}))
    }
  }

  updateCourseProgress(moduleIndex, pageIndex) {
    let modules = this.state.courseProgress.modules;

    let moduleToUpdate = modules[moduleIndex];
    moduleToUpdate["currentPage"] = pageIndex;
    moduleToUpdate["maxPage"] =
      pageIndex > moduleToUpdate["maxPage"]
        ? pageIndex
        : moduleToUpdate["maxPage"];
    modules[moduleIndex] = moduleToUpdate;

    let courseProgress = this.state.courseProgress;
    courseProgress["modules"] = modules;

    this.updateCourseData(courseProgress);

  }

  async updateCourseData(courseProgress) {
    let response = await updateEnrolledCoursesProgress(
      this.data._id,
      courseProgress
    );

    if (response) {
        this.setState(()=>({courseProgress: response}))
    }
  }

  renderComponent(component, data) {
    this.setState(() => ({ component: component, data: data }));
  }

  openModule(module) {
    this.renderComponent(ModuleManager, module);
  }

  modules() {
    return (
      <>
        {this.data.modules.map((module) => {
          return (
            <div onClick={() => this.openModule(module)}>
              <h1>{module.title}</h1>
              <p>{module.description}</p>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    if (this.state.component) {
      let Component = this.state.component;
      return <Component root={this} data={this.state.data}></Component>;
    }

    if (!this.state.courseProgress) {
        this.getCourseData();
        return <></>
    }

    return (
      <div>
        <h1>{this.data.name}</h1>
        <div>{this.modules()}</div>
      </div>
    );
  }
}

export { MainCourseProgress };
