async function 
saveImage(file, oldSrc) {
    const data = new FormData();
    data.append("file", file);
    data.append("metadata", oldSrc);
    const resp = await fetch(
        '/api/v1/admin/images',
        {
            method: 'POST',
            body: data
        }
    )
    if (resp.status == 200) {
        const data = await resp.json();
        return data.image_id;
    }
}


async function deleteImage(id) {
    const resp = await fetch(
        `/api/v1/admin/images/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    if (resp.status == 200) {
        return;
    }
}

export {
    saveImage,
    deleteImage
}