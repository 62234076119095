import React from "react";
import "./style.css";
import { formatter } from "../../../dateutils/names";

let walletOptions = [
  {
    label: "Retirar",
    callback: (component) => {
      component.requestWithdraw();
    },
    icon: "",
  },
  {
    label: "Documentos",
    callback: () => {},
    icon: "",
  },
  {
    label: "Calculadora",
    callback: () => {},
    icon: "",
  },
];

class MoneyWithdraw extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
  }
  render() {
    return (
      <div>
        <label>Cantidad a retirar</label>
        <input></input>
      </div>
    );
  }
}

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {
      balance: this.data.balance,
      component: null
    };
  }

  async requestWithdraw() {
    console.log("Requesting Money withdraw");
    return;
    let response = await fetch(`/api/v1/admin/transactions/withdraw`, {
      method: "POST",
      headers: {
        "Contet-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
  }

  render() {
    if (this.state.component) {
      let Component = this.state.component;
      return <Component  root={this} data={{}}></Component>
    }
    return (
      <div className="wallet">
        <div className="wallet-profile">
          <img src={this.data.user.profile_image}></img>
          <p>{this.data.user.name}</p>
        </div>
        <div className="wallet-balance">
          <h1>Estado de cuenta</h1>
          <p>
            {formatter.format(
              this.state.balance ? this.state.balance.balance : 0
            )}
          </p>
        </div>
        <div className="wallet-options">
          {walletOptions.map((option) => {
            return (
              <div
                className="wallet-option"
                onClick={() => option.callback(this)}
              >
                <img className={"icon " + option.icon}></img>
                <p>{option.label}</p>
              </div>
            );
          })}
        </div>

        <div className="history">
          <h1>Movimientos</h1>

          {this.state.balance &&
            this.state.balance.purchases &&
            this.state.balance.purchases.map((purchase) => {
              return (
                <div className="wallet-history-item">
                  <div>
                    <h1>Ingreso</h1>
                    <p>{purchase.type}</p>
                    <p>{purchase.last_updated}</p>
                  </div>
                  <h2>
                    {purchase.type == "withdraw" ? "" : "+"}
                    {formatter.format(purchase.amount || 0)}
                  </h2>
                </div>
              );
            })}

          {this.state.balance.transactions &&
            this.state.balance.transactions.map((purchase) => {
              return <p>{purchase._id}</p>;
            })}
        </div>
        <div className="nav-ops">
          <p onClick={() => this.root.renderComponent(null, null)}>Salir</p>
        </div>
      </div>
    );
  }
}

class Accounting extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.userInfo;
    this.state = {
      expanded: false,
      purchases: null,
      monthSales: null,
      message: "",
      commissions: null,
      balance: null,
    };
  }

  toggle() {
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  async getPurchases() {
    let req = await fetch(`/api/v1/admin/purchases`);
    if (req.status == 200) {
      let data = await req.json();
      let purchases = data.purchases;
      let monthSales = data.month_sales;
      this.setState(() => ({
        purchases: purchases,
        monthSales: monthSales,
        netIncome: data.net_income,
        commissions: data.commissions,
        balance: data.balance,
      }));
    }
  }

  render() {
    let content;
    let message = this.state.message;

    if (!this.state.purchases) {
      this.getPurchases();
      return <></>;
    }

    let combinedFee = this.state.commissions.filter(
      (c) => c.type == "combined fee"
    )[0];

    if (this.state.expanded) {
      content = (
        <div className="accounting-content">
          <div className="accounting-charts">
            <div className="currency-chart">
              <p>Total de ventas</p>
              <h1>{formatter.format(this.state.netIncome)}</h1>
            </div>
            <div
              className="currency-chart"
              style={{ backgroundColor: "var(--second-shadow)" }}
              onClick={() => {
                this.root.renderComponent(Wallet, {
                  balance: this.state.balance,
                  user: this.userInfo,
                });
              }}
            >
              <p>Balance Total</p>
              <h1>
                {formatter.format(
                  this.state.balance ? this.state.balance.balance : 0
                )}
              </h1>
            </div>
            {/* <div
              className="currency-chart"
              style={{ backgroundColor: "var(--second-shadow)" }}
            >
              <p>Ingreso Neto</p>
              <h1>{formatter.format(this.state.netIncome)}</h1>
            </div> */}
          </div>

          {/* <div className="accounting-charts">
            <div
              className="currency-chart"
              style={{ backgroundColor: "orange" }}
            >
              <p>Comissiones</p>
              <h1>{formatter.format(combinedFee.amount)}</h1>
            </div>
            
          </div> */}

          {/* {this.state.commissions.map((commission) => {
            return (
              <div className="fees">
                <p>
                  {commission.type} {commission.percent}%
                </p>
                <h1>{formatter.format(commission.amount)}</h1>
              </div>
            );
          })} */}
          <div className="purchases">
            <h1>Transacciones</h1>

            {this.state.purchases.map((purchase) => {
              return (
                <div className="purchase">
                  <div>
                    {purchase.user && purchase.user.name ? (
                      <>
                        <p>{purchase.user.name}</p>
                        <span>{purchase.entity_type}</span>
                        <span>{purchase.last_updated}</span>
                      </>
                    ) : (
                      <>
                        <p>{purchase.entity_type}</p>
                        <span>{purchase.last_updated}</span>
                      </>
                    )}
                  </div>
                  <h1>
                    {formatter.format(
                      purchase.entity && purchase.entity.seller_total
                        ? purchase.entity.seller_total
                        : purchase.pay_link.amount
                    )}
                  </h1>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div
        className={`accounting-manager ${
          this.state.expanded ? "expanded" : ""
        }`}
        onClick={() => {
          if (!this.state.expanded) {
            this.toggle();
          }
        }}
      >
        <div className="service-main-actions">
          {this.state.expanded ? (
            <>
              <h1>Rendimientos y Balance</h1>
              <div onClick={() => this.toggle()}>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Rendimientos y Balance</h1>
            </>
          )}
        </div>
        {this.state.expanded && content}
        {!this.state.expanded && this.state.netIncome ? (
          <div className="accounting-charts">
            <div className="currency-chart">
              <p>Total de ventas</p>
              <h1>{formatter.format(this.state.netIncome)}</h1>
            </div>
            <div
              className="currency-chart"
              style={{ backgroundColor: "var(--second-shadow)" }}
            >
              <p>Balance</p>
              <h1>
                {formatter.format(
                  this.state.balance ? this.state.balance.balance : 0
                )}
              </h1>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export { Accounting };
