import React from "react";
import { getCurrentTherapistInfo, getGlobaltherapists, getTherapists, setCurrentTherapist } from "../../hooks/therapists";
import './style.css';
import { newUUID } from "../../dateutils/names";
import { sleep } from "../../App";

class TherapistList extends React.Component {
    constructor(props) {
        super(props);
        this.root = props.root;
        this.state = {
            users: null,
            expand: false,
            currentUser: null,
            alert: false
        }
    }

    async getTherapists() {
        let therapists = await getGlobaltherapists();
        let current = await getCurrentTherapistInfo();

        this.setState(()=>({users: therapists, currentUser: current}));
    }

    async setDefaultTherapist() {
        let current = await getCurrentTherapistInfo();
        this.setCurrentTherapist(current);
    }

    setCurrentTherapist(therapist) {
        if (therapist) {
            setCurrentTherapist(therapist);
            this.root.setTherapist(therapist._id);
            this.setState(()=>({currentUser: therapist, expand: false}));
        }
        this.setState(()=>({currentUser: therapist, expand: false}));
        
    }

    async removeAlert() {
        await sleep(5600);
        this.setState(()=>({alert:false}))
    }

    render() {
        if (!this.state.currentUser) {
            this.setDefaultTherapist();
            return <></>
        }
        if (!this.state.users) {
            this.getTherapists();
            return <></>
        }

        if (this.state.alert) {
            this.removeAlert();
        }
        return (
            <div key={newUUID()} className={`therapist-list ${this.state.expand ? 'expanded-therapists' : ''}`}>
                {
                    this.state.alert && (
                        <p className="floating-alert animated-intro">{"Elige Tu Terapeuta aqui"}</p>
                    )
                }
                {
                    !this.state.expand && (
                        <div className="collapsed">
                            <img onClick={()=>this.setState((p)=>({expand: !p.expand}))} className="icon icon-therapist-profile" src={this.state.currentUser && this.state.currentUser.profile_image}></img>
                            <p>{this.state.currentUser && this.state.currentUser.professional_name}</p>
                        </div>
                            
                    )
                    
                }
                {
                    this.state.expand ? (
                        <div>
                            <h1>Terapeutas Disponibles</h1>
                            {
                                this.state.users && this.state.users.map((user)=>{
                                    return (
                                        <div className="therapists-card" onClick={()=>this.setCurrentTherapist(user)}>
                                            <img className='icon' src={user.profile_image}></img>
                                            <p>{user.professional_name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : null
                }
            </div>
        )
    }
}


export {
    TherapistList
}

