import Rect from 'react';
import '../TherapyDetail/styles.css';
import { sleep } from '../../App';
import { COP, formatPrice, formatter } from '../../dateutils/names.js';
import { mockServices } from '../../constants.js';
import { Services } from '../Services/index.js';
import { PacksConfig } from '../Packs/index.js';
import AppointmentsConfig from '../Appointments/index.js';
import { ExplorePage } from '../Explore/index.js';


class TherapyDetail extends Rect.Component {

    constructor(props) {
        super(props);
        this.root = props.root;
        this.data = props.data;
        this.therapist = this.data.clientInfo;
        this.state = {
            renderTest: false
        }
    }

    back() {
        window.open(`/`, "_self");
    }

    renderTest() {
        this.setState((p)=>{
            return {
                renderTest: true
            }
        })
    }

    render() {

        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');

        let detail = this.data;
        let test;

        if (this.state.renderTest) {
            test = (
                <div className='therapy-test'>
                    <span className='icon icon-back' onClick={(e)=>this.setState((p)=>({renderTest: false}))}></span>
                    <h1>Test de guia</h1>
                </div>
            )
        }

        let plans = detail.plans.map((plan, index)=>{
            return (
                <div    key={plan.price}
                        onClick={(e)=>{
                            if (plan.sessions == 1 || !plan.sessions) {
                                this.root.setComponent(AppointmentsConfig, null, {
                                    service: detail,
                                    plan: plan
                                })
                            } else {
                                this.root.setComponent(PacksConfig, null, {
                                    service: detail,
                                    plan: plan,
                                    planIndex: index
                                })
                            }
                            
                        }}
                        className={`plan plan-${index}`}
                    >
                    <h1>{plan.sessions} {plan.sessions == 1 ? 'Sesion de ': 'Sesiones de '} 
                    {plan.sessions == 1 ? `${detail.time} minutos`: `${detail.time} min`}
                    </h1>
                    <h1>{formatter.format(plan.price, true)}</h1>
                </div>
            )
        });

        return(
            <div className='therapy-detail'>
                {test}
                <img src={detail.image}></img>
                <h1>{detail.name}</h1>
                <p>{detail.description}</p>
                {plans}
                <div className='nav-ops'>
                    <p onClick={()=>this.root.setComponent(Services)}>Regresar</p>
                </div>
                
            </div>
        )
    }
}

export default TherapyDetail