import React from "react";
import "./style.css";
import { getPage } from "./Pages";

class ModuleManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {
      maxPage: 0,
      currentPage: 0,
      pages: this.data.pages
    };
  }

  currentPage() {
    let currentPage = this.data.pages[this.state.currentPage];
    let PageComponent = getPage(currentPage.type)
    return (
      <PageComponent root={this} data={currentPage}></PageComponent>
    );
  }

  render() {
    return <div className="module-manager">{this.currentPage()}</div>;
  }
}

export { ModuleManager };
