import React from "react";
import { sleep } from "../../dateutils/names";
import "./style.css";

class VideoCall extends React.Component {
  constructor(props) {
    super(props);
    this.userVideo = React.createRef();
    this.callerVideo = React.createRef();
    this.state = {
      devices: null,
    };
  }

  async loadDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      let camera = devices.filter((dev) => dev.kind == "videoinput");
      // console.log(camera);
      this.setState(() => ({ devices: devices }));
    });
  }

  async enableCamera() {
    await sleep(200);
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        this.callerVideo.current.srcObject = stream;
        this.callerVideo.current.addEventListener("loadedmetadata", () => {
          this.callerVideo.current.play();
        });
      });
  }

  handleRequests() {}

  sendBuffer() {}

  receiveBuffer() {}

  startConnection() {}

  render() {
    if (!this.state.devices) {
      this.loadDevices();
      this.enableCamera();
    }
    return (
      <div className="video-chat">
        <div className="user-video">
          <video ref={this.userVideo}></video>
          <div className="controls"></div>
        </div>
        <div className="caller-video-container">
          <div className="caller-video">
            <video ref={this.callerVideo}></video>
          </div>

          <div className="call-options">
            <div>
              <img className="icon"></img>
            </div>

            <div>
              <img className="icon"></img>
            </div>

            <div>
              <img className="icon"></img>
            </div>

            <div>
              <img className="icon"></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { VideoCall };
