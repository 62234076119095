async function getWorkshops(therapist) {
    let query = '';
    if (therapist) {
        query = `?therapist=${therapist}`
    }
    const resp = await fetch(
        '/api/v1/workshops' + query
    )
    if (resp.status == 200) {
        const data = await resp.json();

        return [data.response, data.accounting_data]
    }
    else {
        return [{error: "error"}, null];
    }
    
}

async function saveWorkshop(data) {
    const resp = await fetch(
        '/api/v1/admin/workshops',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    if (resp.status == 200) {
        const data = await resp.json();
        return data.workshop_id;
    }
}


async function deleteWorkshop(id) {
    const resp = await fetch(
        `/api/v1/admin/workshops/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    if (resp.status == 200) {
        return;
    }
}

export {
    getWorkshops,
    saveWorkshop,
    deleteWorkshop
}