import React from "react";
import { PageManager, PageSelector } from "./Pages";
import { saveImage } from "../../../../hooks/images";
import { newUUID } from "../../../../dateutils/names";
import { sleep } from "../../../../dateutils/names";

class ModuleEditor extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.index = props.index;
    this.moduleData = this.data.modules[this.index];
    this.state = {
      ...this.moduleData,
      editPage: false,
      pageIndex: null,
      alert: null,
      alertData: null,
      pages: this.moduleData.pages || [],
    };
  }

  async uploadCover() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));
      let imageURL = await saveImage(event.target.files[0], this.state.icon);
      component.setState(() => ({ icon: imageURL, uploading: false }));
      await sleep(1000);
      component.saveModule(false);
    };
    input.click();
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      let contents = ev.target.result;
      component.setState(() => ({ icon: contents }));
      // component.setState(() => ({ tones: tones }));
    };
    reader.readAsDataURL(file);
  }

  saveCourse() {
    this.root.saveCourseSilent();
  }

  async saveModule(quit) {
    this.root.setState((p) => {
      let modules = p.modules;
      modules[this.index] = { ...this.state };
      // // // console.log(this.state);
      return { modules: modules, editModule: !quit };
    });
    // this.root.setState((p) => {
    //   let pages = p.pages;
    //   let tmpContent = [...pages];
    //   tmpContent.splice(this.index, 1);
    //   // newContent.push(...pages.splice(this.index + 1));
    //   return { pages: tmpContent };
    // });
    await sleep(300);
    this.saveCourse();
  }

  async updateModule(attribute, value) {
    this.setState((p) => {
      let state = {};
      state[attribute] = value;
      return state;
    });
    // await sleep(300);
    // this.saveModule();
  }

  addPage() {
    this.setState(() => {
      return {
        alert: PageSelector,
      };
    });
  }

  renderPreview(Component, data) {
    this.setState(() => ({
      previewComponent: Component,
      previewData: data,
    }));
  }

  render() {
    let AlertComponent;
    if (this.state.alert) {
      AlertComponent = this.state.alert;
    }
    if (this.state.previewComponent) {
      let PreviewComponent = this.state.previewComponent;
      return (
        <PreviewComponent
          root={this}
          data={this.state.previewData}
        ></PreviewComponent>
      );
    }
    if (this.state.editPage) {
      return (
        <div>
          {AlertComponent && (
            <AlertComponent
              data={this.state.alertData}
              root={this}
            ></AlertComponent>
          )}
          <PageManager
            data={{
              ...this.state.pages[this.state.pageIndex],
              collapsed: false,
            }}
            index={this.state.pageIndex}
            pages={this.state.pages.length}
            root={this}
          ></PageManager>
        </div>
      );
    }

    return (
      <div className="module-editor">
        {AlertComponent && (
          <AlertComponent
            data={this.state.alertData}
            root={this}
          ></AlertComponent>
        )}
        <div className="service-main-actions page-editor">
          <img
            className="icon icon-back"
            onClick={() => {
              this.saveModule(true);
              // this.root.setState(() => ({ editModule: false }));
            }}
          ></img>
          <h1>Modulo {this.index + 1}</h1>
          <div className="add-page" onClick={() => this.addPage()}>
            + Pagina
          </div>
          {/* <button onClick={() => this.saveModule()}>Guardar</button> */}
        </div>

        <div className="editor-content">
          {this.state.icon ? (
            <div
              className="service-main-actions cover active"
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img src={this.state.icon}></img>
              )}
              <p>
                {this.state.uploading
                  ? "Subiendo imagen..."
                  : "Cambiar la portada"}
              </p>
            </div>
          ) : (
            <div
              className="service-main-actions cover"
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img className="icon icon-add-cover"></img>
              )}
              <p>
                {this.state.uploading
                  ? "Subiendo imagen..."
                  : "Sube una portada"}
              </p>
            </div>
          )}
          <label>Titulo</label>
          <br></br>
          <input
            key="title-input"
            value={this.state.title}
            onChange={(e) => this.updateModule("title", e.target.value)}
          ></input>
          <br></br>
          <label>Descripcion</label>
          <br></br>
          <textarea
            key="description-textarea"
            value={this.state.description}
            onChange={(e) => this.updateModule("description", e.target.value)}
          ></textarea>
          <br></br>
          <label>Paginas</label>
          <div className="pages-container">
            {this.state.pages &&
              this.state.pages.map((page, index) => {
                return (
                  <PageManager
                    key={newUUID()}
                    root={this}
                    data={page}
                    index={index}
                    pages={this.state.pages.length}
                  ></PageManager>
                );
              })}
            <button onClick={() => this.addPage()}>Agregar Pagina +</button>
          </div>
        </div>
      </div>
    );
  }
}

export { ModuleEditor };
