import React from "react";
import "./style.css";
import { sleep } from "../../App";

class SearchList extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.list = props.list;
    this.value = props.value; // The key to be saved by the callback
    this.callback = props.callback;
    this.label = props.label;
    this.keys = props.keys;

    this.isObjectList = false;
    this.inputRef = React.createRef();

    this.default = props.default;

    this.state = {
      list: props.list,
      value: "",
      input: null,
      expand: false,
    };
  }

  async selectOption(index, value) {

    let correctedValue = value;

    if (this.isObjectList) {
      correctedValue = this.state.list[index][this.value];
    }

    this.root[this.callback](this.value, correctedValue);

    this.setState(() => ({ value: value, expand: false, input: null }));
  }

  async autoFocusInput() {
    await sleep(200);
    this.inputRef.current.focus();
  }

  render() {
    let list;
    let current = "Ingresa tu " + this.label;
    if (Array.isArray(this.state.list)) {
      list = this.state.list.map((el, index) => {
        let value = "";
        if (typeof el == "object") {
          if (!this.isObjectList) {
            this.isObjectList = true;
          }
          for (const pk of this.keys) {
            value += " " + el[pk];
          }
        } else {
          value = el;
        }

        if (this.default && value.toLowerCase().includes(this.default.toLowerCase())) {
          current = value;
        }

        if (
          (this.state.input &&
            value.toLowerCase().includes(this.state.input.toLowerCase())) ||
          !this.state.input
        ) {
          return <h2 onClick={() => this.selectOption(index, value)}>{value}</h2>;
        }
      });
    } else {
      list = Object.entries(this.state.list).map(([key, values], index) => {
        let value = "";
        for (const pk of this.keys) {
          value += " " + values[pk];
        }

        return <h2 onClick={() => this.selectOption(index, value)}>{value}</h2>;
      });
    }

    if (this.state.expand) {
      this.autoFocusInput();
    }
    

    return (
      <div className="search-list">
        {this.state.expand && (
          <>
            <p>{this.label}</p>
            <div
                  className="search-input-container">
                  <img className="icon icon-back" onClick={()=>this.setState(()=>({expand: false, input: null}))}></img>
              <input
              ref={this.inputRef}
                value={this.state.input}
                
                onChange={(e) =>
                  this.setState(() => ({ input: e.target.value }))
                }
              ></input>
              <div className="list-options">{list}</div>
            </div>
          </>
        )}
        {!this.state.expand && (
          <>
            <p>{this.label}</p>
            <h1 onClick={() => this.setState(() => ({ expand: true }))}>
              {this.state.value || current}
            </h1>
          </>
        )}
      </div>
    );
  }
}

export { SearchList };
