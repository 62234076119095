import React from "react";
import "./style.css";
import { formatter, newUUID } from "../../dateutils/names";

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.shoppingKart = props.kart;

    this.data = props.data;

    this.state = {};
  }

  async addToKart() {
    this.root.addToKart(this.data);
  }

  async removeFromKart() {
    this.root.removeFromKart(this.data);
  }

  async toggleFavorite(product) {
    this.root.toggleFavorite(product);
  }

  render() {
    let product = this.data;

    let count = 0;
    if (this.shoppingKart) {
      this.shoppingKart.list.forEach((prod) => {
        if (prod == product._id) {
          count += 1;
        }
      });
    }
    return (
      <div
        className="product-card"
        key={newUUID()}
        onClick={() =>
          this.root.setState(() => ({
            renderProduct: true,
            currentProduct: {
              product: product
            },
            renderKart: false,
          }))
        }
      >
        {/* <img
          className={`icon icon-fav-product ${product.favorite ? "full" : ""}`}
          onClick={() => this.toggleFavorite(product._id)}
        ></img> */}

        <img src={product.image}></img>

        <div style={{ padding: "12px 0px 0px" }}>
          <p>{product.name}</p>
          <h1>{formatter.format(product.prices[0].real_price)}</h1>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            by <span style={{ fontWeight: "bold" }}>{product.owner_name}</span>
          </p>
        </div>

        {/* <p style={{textAlign: "center"}}>{product.description}</p> */}
        {/* // <div */}
        {/* // style={{
          //   display: "grid",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   width: "100%",
          //   gridTemplateColumns: "calc(50%) 50%",
          // }}
        // > */}

        {/* </div> */}

        {/* {this.shoppingKart && this.shoppingKart.list.includes(product._id) ? (
            <div className="kart-calculator">
              <button onClick={() => this.removeFromKart()}>-</button>
              <p>{count}</p>
              <button onClick={() => this.addToKart()}>+</button>
            </div>
          ) : (
            <button onClick={() => this.addToKart()}>Add</button>
          )} */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          
        </div> */}
      </div>
    );
  }
}

class CartProduct extends ProductCard {
  render() {
    let product = this.data;

    let count = 0;
    if (this.shoppingKart) {
      this.shoppingKart.list.forEach((prod) => {
        if (prod == product._id) {
          count += 1;
        }
      });
    }
    return (
      <div className="cart-product" key={newUUID()}>
        <img src={product.image}></img>
        <div>
          <h1>{product.name}</h1>
          <p>{product.description}</p>
          {/* <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              by{" "}
              <span style={{ fontWeight: "bold" }}>{product.owner_name}</span>
            </p>
          </div> */}
        </div>
        <div style={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              alignItems: "center",
            }}>
          {this.shoppingKart && this.shoppingKart.list.includes(product._id) ? (
            <div className="kart-calculator">
              <button onClick={() => this.removeFromKart()}>-</button>
              <p>{count}</p>
              <button onClick={() => this.addToKart()}>+</button>
            </div>
          ) : (
            ""
          )}
          <h1 style={{ marginTop: "6px", width: "100%", textAlign: "center" }}>
            {formatter.format(Number(product.prices[0].real_price) * count)}
          </h1>
        </div>
      </div>
    );
  }
}

export { ProductCard, CartProduct };
